module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
  },
  purge: {
    enabled: true,
    content: ["./src/**/*.html", "./src/**/*.html", "./src/**/*.jsx", "./src/**/*.js"],
  },
  theme: {
    fontFamily: {
      body: ["Lato", "sans-serif"],
      display: ["Lato", "sans-serif"],
      screens: {
        sm: "640px",
        md: "768px",
        lg: "1024px",
        xl: "1280px",
      },
    },
    extend: {
      colors: {
        gray: {
          "100": "#cfd3d6",
          "200": "#9fa6ad",
          "300": "#707a83",
          "400": "#404d5a",
          "500": "#102131",
          "600": "#0c1925",
          "700": "#081119",
          "800": "#04080c",
          "900": "#000000",
        },
        yellow: {
          "100": "#fff2s1",
          "200": "#ffe5a3",
          "300": "#ffd774",
          "400": "#ffca46",
          "500": "#ffbd18",
          "600": "#bf8e12",
          "700": "#805f0c",
          "800": "#402f06",
          "900": "#000000",
        },
        blue: {
          "100": "#F2F7FB",
          "200": "#E6EBEF",
          "300": "#CED8DF",
          "400": "#849DAE",
          "500": "#637683",
          "600": "#576972",
          "700": "#424F57",
          "800": "#21272C",
          "900": "#000000",
        },
      },
    },
  },
  variants: {
    display: ['responsive', 'focus','hover', 'group-hover'],
    height: ['responsive', 'hover', 'focus'],
    borderWidth: ['responsive', 'last', 'hover', 'focus', 'active', 'visited'],
    backgroundColor: ['responsive', 'disabled', 'hover', 'focus', 'active', 'visited'],
    textColor: ['responsive', 'hover', 'focus', 'active', 'visited', 'disabled'],
    margin: ['responsive', 'hover', 'focus', 'first', 'last'],
    padding: ['responsive', 'hover', 'focus', 'first', 'last'],
    cursor: ['responsive', 'hover', 'focus'],
    textTransform: ['responsive', 'hover', 'focus'],
    fontWeight: ['responsive', 'hover', 'focus', 'active', 'group-hover'],
    textAlign: ['responsive', 'hover', 'focus'],
    borderColor: ['responsive', 'hover', 'focus', 'disabled', 'active', 'group-hover'],
    minHeight: ['responsive', 'hover', 'focus'],
    verticalAlign: ['responsive', 'hover', 'focus'],
    alignItems: ['responsive','hover', 'focus'],
    justifyContent: ['responsive','hover', 'focus'],
    overflow: ['responsive','hover', 'focus'],
    visibility: ['responsive','hover', 'focus'],
    alignContent: ['responsive','hover', 'focus'],
  },
  plugins: [],
};
