import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import IDProtectionIllustration from "../../../../svg/ComparisonResultPageIllustrations/identityprotectionservices.svg";
import LifeLockSVG from "../../../../svg/ComparisonResultGridLogos/identityprotection/lifelock.svg";
import IDSheildSVG from "../../../../svg/ComparisonResultGridLogos/identityprotection/idshield.svg";
import IDWatchdogSVG from "../../../../svg/ComparisonResultGridLogos/identityprotection/idwatchdog.svg";
import IdentityGuardSVG from "../../../../svg/ComparisonResultGridLogos/identityprotection/identityguard.svg";
import MYFicoSVG from "../../../../svg/ComparisonResultGridLogos/identityprotection/myfico.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  IDProtectionIllustration,
  LifeLockSVG,
  IDSheildSVG,
  IDWatchdogSVG,
  IdentityGuardSVG,
  MYFicoSVG,
  React
};