import React, { useContext } from "react";
import ComparisonResultPageBlogSideBar from "../ComparisonResultPageBlogSideBar";
import WindowSizeContext from "../../contexts/windowSizeContext";
import BlogIndexSmallNewsletterForm from "../BlogIndexSmallNewsletterForm";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { blogContentCategory } = props;
  const windowSize = useContext(WindowSizeContext);

  if (typeof window === `undefined`) {
    return <></>;
  }

  if (windowSize.isLargeScreenView) {
    return (
      <div className="container mx-auto font-body">
        <main>
          <div key={uuidv4()} className="flex flex-wrap mt-10 overflow-hidden">
            <div key={uuidv4()} className="w-full overflow-hidden xl:w-1/12"></div>
            <div key={uuidv4()} className="flex flex-1 w-full overflow-hidden xl:w-10/12">
              <div key={uuidv4()} className="w-3/4 mr-1">
                {props.children}
              </div>
              <div className="w-1/4 bg-white">
                <ComparisonResultPageBlogSideBar key={uuidv4()} blogContentCategory={blogContentCategory} />
              </div>
            </div>
            <div className="w-full overflow-hidden xl:w-1/12"></div>
          </div>
        </main>
      </div>
    );
  } else {
    return (
      <div className="container mx-auto">
        <main className="">
          <div className="flex flex-wrap m-2 overflow-hidden">
            <div className="flex flex-1 w-full overflow-hidden xl:w-full">
              <div className="w-full mr-1">{props.children}</div>
            </div>
          </div>

          <div className="pb-2 bg-blue-100">
            <div className="flex flex-wrap pb-2 mt-2 overflow-hidden">
              <div className="w-full bg-white">
                <ComparisonResultPageBlogSideBar key={uuidv4()} blogContentCategory={blogContentCategory} />
              </div>
            </div>
          </div>
          <section className={`${windowSize.isLargeScreenView ? `hidden` : `block`} bg-blue-100`}>
            <div className="w-full px-0 mx-0 overflow-hidden">
              <div className="container mx-auto">
                <BlogIndexSmallNewsletterForm key={uuidv4()} />
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
};
