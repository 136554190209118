import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import StudentLoanIllustration from "../../../../svg/comparison-illustrations/studentrefinancingloan.svg";
import SplashFinancialLogo from "../../../../svg/supplier-color-logos/SplashFinancial.svg";
import EarnestLogo from "../../../../svg/supplier-color-logos/Earnest.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  StudentLoanIllustration,
  SplashFinancialLogo,
  EarnestLogo,
  React
};