import React, { useContext } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import TCCOutboundLink from "../TCCOutboundLink";
import IpGeoDataContext from "../../contexts/ipGeoDataContext";

/**
 * The ComparisonResultGridLinkButton component renders a TCCOutboundLink component configured with the
 * provided data from the parent Comparison Result column.
 *
 * @param {object} column A JSON object containing the data used to configure the button passed from the parent column.
 *
 * @returns {element} ComparisonResultGridLinkButton An element which will render a TCCOutboundLink which connects the user with the configured affiliate deal.
 */
const ComparisonResultGridLinkButton = (column) => {
  const { claimButtonConfiguration } = column;
  const standardButtonStyles = "text-gray-500 bg-yellow-500 border-yellow-500";
  const focusedButtonStyles = "focus:bg-yellow-500 focus:text-gray-500 focus:border-yellow-500";
  const hoverButtonStyles = "hover:bg-gray-500 hover:text-yellow-500 hover:border-gray-500";

  const ipGeoData = useContext(IpGeoDataContext);
  const { postalCode } = ipGeoData;

  /**
   * Will take a link url to a supplier page and update it with data from the supplied params object.
   *
   * @param {String} link A String containing the link data from the grid configuration.
   * @param {Object} params An object with any number of arguments that can be passed in to be added to the supplied link.
   */
  const generateLinkUrlWithParams = (link, params) => {
    if (!params) {
      return link;
    }

    let generateLinkUrl = link;

    if (params.zipCode) {
      const ZIPCODE_PLACEHOLDER = "zipcode=XXXXX";
      const zipCodeParam = `zipcode=${params.zipCode}`;
      generateLinkUrl = link.replace(ZIPCODE_PLACEHOLDER, zipCodeParam);
    }

    return generateLinkUrl;
  };

  return (
    <div className="inline-block w-full p-2 text-center align-top lg:w-1/5">
      <TCCOutboundLink
        className={`inline-flex items-center w-10/12 h-12 mx-auto border text-center ${standardButtonStyles} ${hoverButtonStyles} ${focusedButtonStyles} `}
        title={claimButtonConfiguration.title}
        href={generateLinkUrlWithParams(claimButtonConfiguration.targetUrl, { zipCode: postalCode })}
      >
        <div className="items-center w-full text-center align-middle">
          <span className="inline-flex">{claimButtonConfiguration.text.length > 0 ? claimButtonConfiguration.text : "Claim Deal"}</span>
          <span className="inline-flex pl-1 align-middle">{claimButtonConfiguration.showIcon === true ? <ArrowRightOutlined /> : " "}</span>
        </div>
      </TCCOutboundLink>
      <div className="lg:inline-block">
        <p className="mt-3 text-xs text-gray-500">{claimButtonConfiguration.subText}</p>
      </div>
    </div>
  );
};

export default ComparisonResultGridLinkButton;
