import React from "react";

const isValid = (obj) => {
  return obj && obj.length > 0;
};

export const sortByIndexAscending = (data) => {
  return data.sort((a, b) => {
    return a.index - b.index;
  });
};

export const getSupplierLogoElementUsing = (children, column) => {
  const logo = children.filter((c) => c.props.mdxType === column.logoElementKey);
  return logo;
};

export const getGridLabelColumnFrom = (columnConfigurations) => {
  return columnConfigurations.find((c) => c.index === 1);
};

export const getGridDataColumnsFrom = (columnConfigurations) => {
  return columnConfigurations
    .filter((c) => c.index > 1)
    .sort((a, b) => {
      return a.index - b.index;
    });
};

export const shouldApplyBackgroundTo = (row) => {
  if (!row) {
    throw new Error("Null or Empty row, unable to determine if it is even or not");
  }

  return parseInt(row.index) % 2 === 0;
};

export const createResultGridImageMapFrom = (children) => {
  if (!isValid(children)) {
    throw new Error("Null or Empty children array, unable to extract result grid image elements.");
  }

  const results = React.Children.toArray(children).map((child) => {
    return { imageKey: child.props.imagekey, element: child };
  });

  return results;
};

export const generateGridMetaDataUsing = (labelColumnConfiguration, dataColumnsConfiguration) => {
  return {
    labelColumnIndex: labelColumnConfiguration.index,
    dataColumnIndexes: {
      firstDataColumnIndex: dataColumnsConfiguration[0].index,
      lastDataColumnIndex: dataColumnsConfiguration[dataColumnsConfiguration.length - 1].index,
    },
    showHeaderRow: true,
  };
};

export const renderExpanderUsing = (dataColumnsConfiguration) => {
  if (dataColumnsConfiguration.index === 1) {
    return dataColumnsConfiguration.expansionRows.length > 0;
  }

  return false;
};
