import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import LifeInsuranceIllustration from "../../../../svg/ComparisonResultPageIllustrations/lifeinsurance.svg";
import HealthIQSVG from "../../../../svg/ComparisonResultGridLogos/lifeinsurance/healthiq.svg";
import BestowSVG from "../../../../svg/ComparisonResultGridLogos/lifeinsurance/bestow.svg";
import HavenLifeSVG from "../../../../svg/ComparisonResultGridLogos/lifeinsurance/havenlife.svg";
import SprouttSVG from "../../../../svg/ComparisonResultGridLogos/lifeinsurance/sproutt.svg";
import LadderSVG from "../../../../svg/ComparisonResultGridLogos/lifeinsurance/ladder.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  LifeInsuranceIllustration,
  HealthIQSVG,
  BestowSVG,
  HavenLifeSVG,
  SprouttSVG,
  LadderSVG,
  React
};