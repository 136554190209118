import React, { useState } from "react";
import { Input, Button, Notification } from "antd";
import { MailOutlined } from "@ant-design/icons";
import axios from "axios";
import tccNotification from "../../utils/tccNotification";
import { isNullEmptyOrUndefined } from "../../utils/StringUtils";

export default () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const [emailAddress, setEmailAddress] = useState("");

  const handleEmailAddressChange = (evt) => {
    setEmailAddress(evt.target.value);
  };

  const displayNotificationUsing = (notificationArguments) => {
    Notification.open(notificationArguments);
  };

  const handleServerResponse = (ok, msg, form) => {
    const notificationArguments = ok
      ? tccNotification.buildSuccessNotificationArgsFrom("Thanks", "Your email address has been received & we will send our latest deals real soon.")
      : tccNotification.buildFailedNotificationArgsFrom(
          "There was an error",
          "Thank you for trying to submit your email address. It seems there was a problem that was likely our fault. Please try again."
        );

    displayNotificationUsing(notificationArguments);

    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
      setEmailAddress("");
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/69040f17-0b57-46c1-8c3a-4418644ebc64",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Done", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <form className="w-full py-4 bg-yellow-500" onSubmit={handleOnSubmit}>
      <p className="text-base text-center text-gray-500 ">Suscríbete al boletín</p>
      <div className="flex flex-wrap overflow-hidden">
        <div className="w-11/12 mx-auto overflow-hidden">
          <label htmlFor="emailInput" className="hidden" required="required">
            Email address
          </label>
          <Input
            type="email"
            name="email"
            value={emailAddress}
            onChange={handleEmailAddressChange}
            className=""
            id="emailInput"
            aria-describedby="emailHelp"
            size="large"
            placeholder="Correo electrónico"
            prefix={<MailOutlined className="pt-1 text-gray-100" />}
          />
        </div>

        <div className="w-9/12 mx-auto overflow-hidden">
          <Button
            htmlType="submit"
            className="w-full h-10 mt-4 text-yellow-500 bg-gray-500 border border-gray-500 rounded hover:shadow-xl disabled:text-gray-300 disabled:bg-gray-500 disabled:border-gray-500"
            disabled={isNullEmptyOrUndefined(emailAddress) || serverState.submitting}
          >
            Regístrate
          </Button>
        </div>
      </div>
    </form>
  );
};
