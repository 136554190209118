import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import HomeLoanIllustration from "../../../../svg/ComparisonResultPageIllustrations/homeloan.svg";
import CCUSVG from "../../../../svg/ComparisonResultGridLogos/homeloans/consumercreditunion.svg";
import BetterSVG from "../../../../svg/ComparisonResultGridLogos/homeloans/better.svg";
import QuickenSVG from "../../../../svg/ComparisonResultGridLogos/homeloans/quicken.svg";
import RocketSVG from "../../../../svg/ComparisonResultGridLogos/homeloans/rocketmortgage.svg";
import BoASVG from "../../../../svg/ComparisonResultGridLogos/homeloans/bankofamerica.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  HomeLoanIllustration,
  CCUSVG,
  BetterSVG,
  QuickenSVG,
  RocketSVG,
  BoASVG,
  React
};