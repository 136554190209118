import React from "react";

import BusinessBankingSvg from "../../svg/RecommendedProductIcons/Business/businessbanking.svg";
import BusinessCreditCardsSvg from "../../svg/RecommendedProductIcons/Business/businessbanking.svg";
import BusinessCreditServicesSvg from "../../svg/RecommendedProductIcons/Business/businesscreditcards.svg";
import BusinessLoansSvg from "../../svg/RecommendedProductIcons/Business/businessloans.svg";
import BusinessPayrollSvg from "../../svg/RecommendedProductIcons/Business/businesspayroll.svg";
import WebHostingSvg from "../../svg/RecommendedProductIcons/Business/webdomainservies.svg";
import PersonalBankingSvg from "../../svg/RecommendedProductIcons/Financial/personalbanking.svg";
import StudentRefinancingSvg from "../../svg/RecommendedProductIcons/Loans/studentrefinancing.svg";
import TVPackagesSvg from "../../svg/RecommendedProductIcons/Home/tvicon.svg";
import HomeLoansSvg from "../../svg/RecommendedProductIcons/Loans/homeloans.svg";
import InternetSvg from "../../svg/RecommendedProductIcons/Home/homeinternet.svg";
import ElectricRatesSvg from "../../svg/RecommendedProductIcons/Utilities/electricityrates.svg";
import IDMonitoringSvg from "../../svg/RecommendedProductIcons/Financial/identityprotection.svg";
import PersonalBudgetingSvg from "../../svg/RecommendedProductIcons/Financial/personalbudgeting.svg";
import RefinancingLoansSvg from "../../svg/RecommendedProductIcons/Loans/refinancingloans.svg";
import CarLoanSvg from "../../svg/RecommendedProductIcons/Loans/carloans.svg";
import CarInsuranceSvg from "../../svg/RecommendedProductIcons/Insurance/carinsurance.svg";

/**
 * The configurations defined here require the following fields:
 *
 * - productCategory - The product category name as shown in the corresponding navigation menu item. This will also be used as the recommended product tiles heading if no title field is defined.
 * - title - (Optional) - The text used as the header in the recommended product tile.
 * - text - The body text shown in the recommended product tile.
 * - image - The SVG element to use (with styling applied) to the recommended product tile.
 * - relatedProducts - A string array containing the product category's that will consume the configuration.
 */
export default {
  recommendedProductConfigurations: [
    {
      productCategory: "Tarifas Eléctricas",
      text: "Cámbiate de proveedor y paga desde sólo $57.45.",
      image: <ElectricRatesSvg className="h-6" />,
      relatedProducts: [],
    },
    {
      productCategory: "Seguro de auto",
      text: "Primas desde $60 para personas de 25 años",
      image: <CarInsuranceSvg className="h-6" />,
      relatedProducts: ["teléfonos móviles"],
    },
    {
      productCategory: "Préstamos para automóviles",
      text: "Préstamos desde 2.69% por $12,720",
      image: <CarLoanSvg className="h-6" />,
      relatedProducts: [],
    },

    {
      productCategory: "Teléfonos Móviles",
      text: "Apple iPhone SE Desde $349",
      image: <TVPackagesSvg className="h-6" />,
      relatedProducts: [],
    },

    {
      productCategory: "paquetes de tv",
      text: "Encuentra el contenido perfecto para verlo todo el día desde $39.99.",
      image: <TVPackagesSvg className="h-6" />,
      relatedProducts: ["teléfonos móviles"],
    },
    {
      productCategory: "Servicios de Internet en el hogar",
      title: "Servicios de Internet",
      text: "Todo el poder de navegación con ofertas desde $35.",
      image: <InternetSvg className="h-6" />,
      relatedProducts: [],
    },
  ],
};
