import React from "react";
import { Link } from "gatsby";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { articleData } = props;
  const itemKey = uuidv4();

  return (
    <>
      <li className="list-none" key={itemKey}>
        <div className="inline-block">
          <p>
            <Link className="text-sm text-blue-700 hover:text-blue-700 hover:underline" to={articleData.slug}>{`${articleData.blogTitle}`}</Link>
          </p>
        </div>
      </li>
    </>
  );
};
