import { useStaticQuery, graphql } from "gatsby";
import * as blogService from "../services/BlogService";

/**
 * Queries the Blog Post MDX node data and returns an object of the pre-processed results.
 *
 * @returns (object) Containing the processed data.
 *
 *  @returns (object.blogPostData) An array containing all of the MDX Blog node data.
 *  @returns (object.blogPostCategories) An array containing the list of Blog Categories.
 *  @returns (object.blogPostArchiveData) An array containing the list of Blog Archive details.
 */
function useBlogData() {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fields: { parentMenu: { eq: "blog" }, blogDate: {} } }, sort: { fields: frontmatter___date, order: DESC }) {
          edges {
            node {
              id
              fields {
                parentMenu
                blogCategory
                blogDate
                blogTitle
                blogSummary
                slug
              }
            }
          }
        }
      }
    `
  );

  const edges = allMdx.edges;
  const blogPostData = blogService.getBlogPostCategoryDataFrom(edges);
  const blogPostCategories = blogService.getGroupedBlogPostCategoryDataFrom(blogPostData);
  const blogPostArchiveData = blogService.getGroupedBlogPostArchiveDataFrom(blogPostData);

  return {
    blogPostData: blogPostData,
    blogPostCategories: blogPostCategories,
    blogPostArchiveData: blogPostArchiveData,
  };
}

export default useBlogData;
