import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a container component based on supplied child elements, with set styling for use in the Comparison Results Page Column Content Component.
 *
 * @param {Props} props Array of data passed to the function from the parent component and the rendering pipeline.
 */
const ComparisonResultPageLearnMoreContainer = (props) => {
  return <div className="pb-8 bg-white">{props.children}</div>;
};

ComparisonResultPageLearnMoreContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

export default ComparisonResultPageLearnMoreContainer;
