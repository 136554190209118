import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import TaxServicesIllustration from "../../../../svg/ComparisonResultPageIllustrations/personaltaxservice.svg";
import HRBlockSVG from "../../../../svg/ComparisonResultGridLogos/personaltaxservices/handrblock.svg";
import TurboTaxSVG from "../../../../svg/ComparisonResultGridLogos/personaltaxservices/turbotax.svg";
import TaxSlayerSVG from "../../../../svg/ComparisonResultGridLogos/personaltaxservices/taxslayer.svg";
import TaxActSVG from "../../../../svg/ComparisonResultGridLogos/personaltaxservices/taxact.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  TaxServicesIllustration,
  HRBlockSVG,
  TurboTaxSVG,
  TaxSlayerSVG,
  TaxActSVG,
  React
};