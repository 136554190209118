import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import BusinessBankingIllustration from "../../../../svg/ComparisonResultPageIllustrations/businessbanking.svg";
import CCUSVG from "../../../../svg/ComparisonResultGridLogos/businessbanking/consumercreditunion.svg";
import BankNovoSVG from "../../../../svg/ComparisonResultGridLogos/businessbanking/novo.svg";
import ChaseSVG from "../../../../svg/ComparisonResultGridLogos/businessbanking/chasebank.svg";
import AxosSVG from "../../../../svg/ComparisonResultGridLogos/businessbanking/axosbank.svg";
import CapitalOneSVG from "../../../../svg/ComparisonResultGridLogos/businessbanking/capitalone.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  BusinessBankingIllustration,
  CCUSVG,
  BankNovoSVG,
  ChaseSVG,
  AxosSVG,
  CapitalOneSVG,
  React
};