/**
 * Determines if the provided argument is a valid Array.
 *
 * @param {Array} array The value to test.
 * @returns {boolean} True if the argument is a valid Array otherwise false;
 */
export const isValid = (array) => {
  return Array.isArray(array);
};

/**
 * Determines if the provided argument is a valid, non empty, Array.
 *
 * @param {Array} array The value to test.
 * @returns {boolean} True if the argument is a valid Array with one or more elements otherwise false;
 */
export const isValidNonEmpty = (array) => {
  return Array.isArray(array) && array.length > 0;
};

/**
 * Will split the provided Array into a number of smaller arrays containing the specified number of items in each sub array.
 *
 * @param {Array} array The Array to split into multiple Arrays of chunkSize.
 * @param {number} chunkSize The size of sub array's to split the original arry into.
 * @returns{Array} An Array containing the split arrays.
 * @throws {Error} If the supplied Array is not a valid array or if it is empty.
 */
export const chunk = (array, chunkSize) => {
  if (!isValidNonEmpty(array)) {
    throw new Error("Invalid or empty array, unable to generate chunked array set.");
  }

  let index = 0;
  let temporaryArray = [];
  const arrayLength = array.length;

  for (index = 0; index < arrayLength; index += chunkSize) {
    let arrayChunk = array.slice(index, index + chunkSize);
    temporaryArray.push(arrayChunk);
  }

  return temporaryArray;
};

/**
 * Resorts the supplied Array randomly.
 *
 * If the supplied Array is not actually an Array or is empty will return the supplied value.
 *
 * @param {Array} array The Array structure to randomly sort.
 * @returns{Array} The supplied Array randomly sorted.
 */
export const randomize = (array) => {
  if (!isValidNonEmpty(array)) return array;

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  return array;
};

/**
 * Takes the requested number of elements from the Array randomly.
 *
 * If the supplied Array is not actually an Array or is empty will return the supplied value.
 *
 * If the total number of Array elements is less than the requested number then will return the supplied Array.
 *
 * @param {Array} array The Array structure to select random elements from.
 * @param{number} numberOfElements The number of random elements to select from the provided Array.
 * @returns{Array} The supplied Array randomly sorted.
 * @throws {Error} If the numberOfElements parameter is less than or equal to zero.
 */
export const getRandomNumberOfElementsFrom = (array, numberOfElements) => {
  if (!isValidNonEmpty(array)) return array;

  if (numberOfElements <= 0) {
    throw new Error("numberOfElements is less than or equal to zero. Unable to return a random number of elements from the requested array.");
  }

  if (array.length < numberOfElements) return array;

  return array.sort(() => 0.5 - Math.random()).slice(0, numberOfElements);
};
