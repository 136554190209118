import React, { useContext } from "react";
import { useState } from "react";
import { Link, navigate } from "@reach/router";
import { getUser, isLoggedIn, logout } from "../../utils/auth";
import { useFirebase } from "gatsby-plugin-firebase";
import { Avatar, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import StatusMenu from "../StatusMenu";
import AnonymousStatus from "../AnonymousStatus";
import * as StatusMenuUtils from "../../utils/StatusMenuUtils";
import tailwindConfig from "../../../tailwind.config.js";
import * as tailwindUtils from "../../utils/tailwindUtils";
import { v4 as uuidv4 } from "uuid";
import WindowSizeContext from "../../contexts/windowSizeContext";

const { SubMenu } = Menu;
const itemKey = uuidv4();

export default () => {
  const [firebase, setFirebase] = useState();
  const windowSize = useContext(WindowSizeContext);

  useFirebase((fb) => {
    setFirebase(fb);
  }, []);

  let details;
  if (!isLoggedIn()) {
    details = <AnonymousStatus />;
  } else {
    const { displayName, email, photoURL } = getUser();
    details = (
      <div className="inline-flex items-center justify-end flex-grow w-full px-0 py-0 align-middle">
        <Menu mode={windowSize.isLargeScreenView ? `horizontal` : `inline`} className="content-end py-0 border-white">
          <SubMenu
            title={
              <>
                <span className={`${windowSize.isLargeScreenView ? `block` : `hidden`}`}>
                  <Avatar shape="square" size={40} className="text-xl text-white bg-blue-500" src={`${photoURL ? photoURL : ``}`}>
                    {StatusMenuUtils.getAvatarCharacterFrom(displayName)}
                  </Avatar>
                  <CaretDownOutlined className="pl-1 text-blue-500" />
                </span>
                <span className={`${windowSize.isLargeScreenView ? `hidden` : `block`} pl-4 text-gray-800`}>My Profile</span>
              </>
            }
            className="items-end"
          >
            <li key={itemKey} className={`${windowSize.isLargeScreenView ? `block` : `hidden`} lg:block w-full ant-menu-item-only-child`}>
              <StatusMenu name={displayName} email={email} photoURL={photoURL} />
            </li>
            <hr className={`${windowSize.isLargeScreenView ? `block` : `hidden`} mx-2 my-2 border-blue-500 `} />
            <Menu.Item className="w-full">
              <Link to="/app/profile" className="lg:pl-10">
                My Profile
              </Link>
            </Menu.Item>
            <Menu.Item className="w-full">
              <Link to="/blog" className="lg:pl-10">
                Blog
              </Link>
            </Menu.Item>
            <Menu.Item className="w-full">
              <Link
                to="/user-profile"
                onClick={(event) => {
                  event.preventDefault();
                  logout(firebase).then(() => navigate(`/`));
                }}
                className="lg:pl-10"
              >
                Logout
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </div>
    );
  }

  return <div>{details}</div>;
};
