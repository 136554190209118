import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import WebHostingIllustration from "../../../../svg/ComparisonResultPageIllustrations/webhostingservices.svg";
import BlueHostSVG from "../../../../svg/ComparisonResultGridLogos/webhosting/bluehost.svg";
import HostgatorSVG from "../../../../svg/ComparisonResultGridLogos/webhosting/hostgator.svg";
import InmotionSVG from "../../../../svg/ComparisonResultGridLogos/webhosting/inmotion.svg";
import A2hostingSVG from "../../../../svg/ComparisonResultGridLogos/webhosting/a2hosting.svg";
import NetworkSolutionsSVG from "../../../../svg/ComparisonResultGridLogos/webhosting/networksolutions.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  WebHostingIllustration,
  BlueHostSVG,
  HostgatorSVG,
  InmotionSVG,
  A2hostingSVG,
  NetworkSolutionsSVG,
  React
};