import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import PropTypes from "prop-types";

/**
 * Gatsby Google Analytic Plugin OutboundLink wrapper component. This is used to link to external urls to ensure that Google Analytics captures the traffic.
 *
 * @param {string} className A passthrough of the className attribute set by the parent element.
 * @param {string} title The string to be displayed in via the links title attribute.
 * @param {string} href A passthrough of the url to link to.
 * @param {string} text A string that will be displayed within the link. This is overridden if children is passed, elements take priority.
 * @param {string} target A passthrough string that will be assigned to the links target attribute. This defaults to _blank if left blank.
 * @param {string} children A single child element to be rendered within the link in place of the text property. The children property takes top rendering priority.
 *
 */
const TCCOutboundLink = ({ className, title, href, text, target, children }) => {
  return (
    <OutboundLink target={target} className={className} rel="noopener noreferrer" title={title} href={href}>
      {children ?? text}
    </OutboundLink>
  );
};

TCCOutboundLink.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string.isRequired,
  text: PropTypes.string,
  target: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.element.isRequired]),
};

TCCOutboundLink.defaultProps = {
  target: "_blank",
};

export default TCCOutboundLink;
