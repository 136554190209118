import { convertToLowerCase, isNotNullEmptyOrUndefined, isNullEmptyOrUndefined } from "../../utils/StringUtils";
import { randomize, getRandomNumberOfElementsFrom } from "../../utils/ArrayUtils";

/**
 * Generates an Array of objects that are used to configure each related recommended product for the requesting page.
 *
 * Will attempt to take the recommended product configurations based on the requestingProductCategory value otherwise will randomly select 6 configurations to ensure the requesting page has data.
 *
 * Whatever configurations are generated/selected, the results will be randomly sorted to make the feature fresh on each page load to prevent user fatigue.
 *
 * @param {string} requestingProductCategory A string containing the menu name of the page requesting the recommended product configurations.
 * @param {Array} recommendedProductConfigurationData An array containing the parsed recommended product data configurations.
 * @returns {Array} An array containing the filtered and processed recommended products configuration data.
 */
const getRequestedRecommendedProductConfigurationsUsing = (requestingProductCategory, recommendedProductConfigurationData) => {
  if (isNullEmptyOrUndefined(requestingProductCategory)) {
    throw new Error("The requestingProductCategory is null, empty or undefined. Unable to query recommended product configurations.");
  }

  if (!recommendedProductConfigurationData || recommendedProductConfigurationData.length === 0) {
    throw new Error("The recommendedProductConfigurationData is null or empty. Unable to query recommended product configurations.");
  }

  const requestingProductCategoryConfig = recommendedProductConfigurationData.find((r) => convertToLowerCase(r.productCategory) === convertToLowerCase(requestingProductCategory));

  const NUMBER_OF_ARRAY_ELEMENTS_TO_RETURN = 6;

  if (requestingProductCategoryConfig) {
    const relatedProductCategories = requestingProductCategoryConfig.relatedProducts.map((pc) => convertToLowerCase(pc));
    const relatedProductCategoryConfigurations = recommendedProductConfigurationData.filter((r) => relatedProductCategories.indexOf(convertToLowerCase(r.productCategory)) >= 0);

    return randomize(getRandomNumberOfElementsFrom(relatedProductCategoryConfigurations, NUMBER_OF_ARRAY_ELEMENTS_TO_RETURN));
  }

  return randomize(getRandomNumberOfElementsFrom(recommendedProductConfigurationData, NUMBER_OF_ARRAY_ELEMENTS_TO_RETURN));
};

/**
 *
 * @param {*} requestingProductCategory
 * @param {*} recommendedProductConfigurationData
 * @param {*} menuData
 */
export const getRecommendedProductConfigurationsUsing = (requestingProductCategory, recommendedProductConfigurationData, productCategoryConfigurationData, menuData) => {
  const recommendedProductConfigurations = getRequestedRecommendedProductConfigurationsUsing(requestingProductCategory, recommendedProductConfigurationData);

  let filteredRecommendedProductConfigurations = [];
  const PREMIUM_SLUG = "/premium";

  recommendedProductConfigurations.forEach((rpc) => {
    const menuItem = menuData.find((md) => convertToLowerCase(md.menuText) === convertToLowerCase(rpc.productCategory));

    if (!menuItem) {
      throw new Error(`Unable to query navigation data for ${rpc.productCategory}. No menuItem found.`);
    }

    const productCategoryConfiguration = productCategoryConfigurationData.find((pc) => convertToLowerCase(pc.category) === convertToLowerCase(menuItem.parentMenu));

    if (!productCategoryConfiguration) {
      throw new Error(`Unable to query recommended products data for ${menuItem.parentMenu}. No configurations found.`);
    }

    const isPremiumRoute = productCategoryConfiguration.premiumSubCatagories.indexOf(convertToLowerCase(rpc.productCategory)) >= 0;

    const recommendedProduct = {
      title: isNotNullEmptyOrUndefined(rpc.title) ? rpc.title : rpc.productCategory,
      text: rpc.text,
      slug: isPremiumRoute || isNullEmptyOrUndefined(menuItem.slug) ? PREMIUM_SLUG : menuItem.slug,
      image: rpc.image,
    };

    filteredRecommendedProductConfigurations.push(recommendedProduct);
  });

  return filteredRecommendedProductConfigurations;
};
