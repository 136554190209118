import React from "react";
import UserLocation from "../UserLocation";
import PropTypes from "prop-types";

/**
 * Renders a component that consumes an instance of the UserLocation component within a text UI element.
 *
 * @param {string} prefixText A string containing the text to render before the UserLocation element.
 * @returns {Element} Renders a div block containing the provided PrefixText and UserLocation elements.
 */
const ComparisonResultHeroUserLocation = (props) => {
  return (
    <div className="flex flex-wrap pt-2 pb-2 overflow-hidden lg:pt-0 lg:pb-4">
      <div className="w-full pl-2 overflow-hidden text-center text-blue-500 lg:text-left lg:pl-1 lg:pt-6">
        <span className="inline-block mr-2 ">{props.prefixText}</span>
        <span className="inline-block">
          <UserLocation />
        </span>
      </div>
    </div>
  );
};

ComparisonResultHeroUserLocation.propTypes = {
  prefixText: PropTypes.string.isRequired,
};

ComparisonResultHeroUserLocation.defaultProps = {
  prefixText: "ofertas para",
};
export default ComparisonResultHeroUserLocation;
