import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import CarInsuranceIllustration from "../../../../svg/ComparisonResultPageIllustrations/carinsurance.svg";
import ProgressiveSVG from "../../../../svg/ComparisonResultGridLogos/carinsurance/progressive.svg";
import GeicoSVG from "../../../../svg/ComparisonResultGridLogos/carinsurance/geico.svg";
import FarmersinsuranceSVG from "../../../../svg/ComparisonResultGridLogos/carinsurance/farmersinsurance.svg";
import LibertyMutualSVG from "../../../../svg/ComparisonResultGridLogos/carinsurance/libertymutual.svg";
import AlLStateSVG from "../../../../svg/ComparisonResultGridLogos/carinsurance/allstate.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  CarInsuranceIllustration,
  ProgressiveSVG,
  GeicoSVG,
  FarmersinsuranceSVG,
  LibertyMutualSVG,
  AlLStateSVG,
  React
};