import React from "react";
import ComingSoonIcon from "../../../svg/comingsoon.svg";
import * as mobileColumnHeaderCellUtils from "./comparisonResultMobileColumnHeaderCellUtils";

export default (props) => {
  const { columnConfiguration, imageMap } = props;
  const supplierLogo = imageMap.find((i) => i.imageKey === mobileColumnHeaderCellUtils.getColumnHeaderConfigurationFrom(columnConfiguration.rows).heading.imageKey);

  return <div className="flex flex-col items-center justify-center w-full h-12">{columnConfiguration.isComingSoon ? <ComingSoonIcon /> : supplierLogo.element}</div>;
};
