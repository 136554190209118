// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-app-jsx": () => import("./../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-blog-2020-august-comparing-life-insurance-quote-could-save-your-thousands-of-dollars-mdx": () => import("./../src/pages/blog/2020/august/comparing-life-insurance-quote-could-save-your-thousands-of-dollars.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-comparing-life-insurance-quote-could-save-your-thousands-of-dollars-mdx" */),
  "component---src-pages-blog-2020-august-how-expensive-was-lockdown-mdx": () => import("./../src/pages/blog/2020/august/how-expensive-was-lockdown.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-how-expensive-was-lockdown-mdx" */),
  "component---src-pages-blog-2020-august-the-top-5-ways-to-save-money-on-electricity-in-2020-mdx": () => import("./../src/pages/blog/2020/august/the-top-5-ways-to-save-money-on-electricity-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-the-top-5-ways-to-save-money-on-electricity-in-2020-mdx" */),
  "component---src-pages-blog-2020-august-what-is-family-life-insurance-6-things-to-know-mdx": () => import("./../src/pages/blog/2020/august/what-is-family-life-insurance-6-things-to-know.mdx" /* webpackChunkName: "component---src-pages-blog-2020-august-what-is-family-life-insurance-6-things-to-know-mdx" */),
  "component---src-pages-blog-2020-july-five-questions-to-ask-when-you-compare-insurance-policies-and-prices-mdx": () => import("./../src/pages/blog/2020/july/five-questions-to-ask-when-you-compare-insurance-policies-and-prices.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-five-questions-to-ask-when-you-compare-insurance-policies-and-prices-mdx" */),
  "component---src-pages-blog-2020-july-four-keys-for-finding-todays-cheapest-internet-offers-mdx": () => import("./../src/pages/blog/2020/july/four-keys-for-finding-todays-cheapest-internet-offers.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-four-keys-for-finding-todays-cheapest-internet-offers-mdx" */),
  "component---src-pages-blog-2020-july-six-benefits-you-get-when-you-compare-electricity-bills-mdx": () => import("./../src/pages/blog/2020/july/six-benefits-you-get-when-you-compare-electricity-bills.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-six-benefits-you-get-when-you-compare-electricity-bills-mdx" */),
  "component---src-pages-blog-2020-july-who-has-the-cheapest-home-insurance-mdx": () => import("./../src/pages/blog/2020/july/who-has-the-cheapest-home-insurance.mdx" /* webpackChunkName: "component---src-pages-blog-2020-july-who-has-the-cheapest-home-insurance-mdx" */),
  "component---src-pages-blog-2020-june-get-a-lower-electrictity-bill-by-comparing-prices-mdx": () => import("./../src/pages/blog/2020/june/get-a-lower-electrictity-bill-by-comparing-prices.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-get-a-lower-electrictity-bill-by-comparing-prices-mdx" */),
  "component---src-pages-blog-2020-june-seven-genius-ways-to-save-money-on-cable-mdx": () => import("./../src/pages/blog/2020/june/seven-genius-ways-to-save-money-on-cable.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-seven-genius-ways-to-save-money-on-cable-mdx" */),
  "component---src-pages-blog-2020-june-six-easy-ways-to-save-money-on-insurance-in-2020-mdx": () => import("./../src/pages/blog/2020/june/six-easy-ways-to-save-money-on-insurance-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-six-easy-ways-to-save-money-on-insurance-in-2020-mdx" */),
  "component---src-pages-blog-2020-june-six-key-things-to-know-about-life-insurance-rates-mdx": () => import("./../src/pages/blog/2020/june/six-key-things-to-know-about-life-insurance-rates.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-six-key-things-to-know-about-life-insurance-rates-mdx" */),
  "component---src-pages-blog-2020-june-where-are-the-best-student-loan-rates-mdx": () => import("./../src/pages/blog/2020/june/where-are-the-best-student-loan-rates.mdx" /* webpackChunkName: "component---src-pages-blog-2020-june-where-are-the-best-student-loan-rates-mdx" */),
  "component---src-pages-blog-2020-may-seven-rules-to-save-money-on-bills-mdx": () => import("./../src/pages/blog/2020/may/seven-rules-to-save-money-on-bills.mdx" /* webpackChunkName: "component---src-pages-blog-2020-may-seven-rules-to-save-money-on-bills-mdx" */),
  "component---src-pages-blog-2020-november-the-8-best-renters-insurance-providers-of-2020-mdx": () => import("./../src/pages/blog/2020/november/the-8-best-renters-insurance-providers-of-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-november-the-8-best-renters-insurance-providers-of-2020-mdx" */),
  "component---src-pages-blog-2020-october-5-best-free-email-marketing-services-mdx": () => import("./../src/pages/blog/2020/october/5-best-free-email-marketing-services.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-5-best-free-email-marketing-services-mdx" */),
  "component---src-pages-blog-2020-october-5-best-password-managers-for-business-mdx": () => import("./../src/pages/blog/2020/october/5-best-password-managers-for-business.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-5-best-password-managers-for-business-mdx" */),
  "component---src-pages-blog-2020-october-how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for-mdx": () => import("./../src/pages/blog/2020/october/how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for.mdx" /* webpackChunkName: "component---src-pages-blog-2020-october-how-can-you-choose-the-best-email-marketing-services-7-essential-things-to-look-for-mdx" */),
  "component---src-pages-blog-2020-september-compare-insurance-policies-without-getting-overwhelmed-mdx": () => import("./../src/pages/blog/2020/september/compare-insurance-policies-without-getting-overwhelmed.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-compare-insurance-policies-without-getting-overwhelmed-mdx" */),
  "component---src-pages-blog-2020-september-eleven-factors-that-affect-your-home-insurance-rate-mdx": () => import("./../src/pages/blog/2020/september/eleven-factors-that-affect-your-home-insurance-rate.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-eleven-factors-that-affect-your-home-insurance-rate-mdx" */),
  "component---src-pages-blog-2020-september-finding-the-right-car-insurance-for-you-mdx": () => import("./../src/pages/blog/2020/september/finding-the-right-car-insurance-for-you.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-finding-the-right-car-insurance-for-you-mdx" */),
  "component---src-pages-blog-2020-september-when-should-you-consider-a-family-life-insurance-plan-mdx": () => import("./../src/pages/blog/2020/september/when-should-you-consider-a-family-life-insurance-plan.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-when-should-you-consider-a-family-life-insurance-plan-mdx" */),
  "component---src-pages-blog-2020-september-where-can-you-find-cheap-tv-rates-in-2020-mdx": () => import("./../src/pages/blog/2020/september/where-can-you-find-cheap-tv-rates-in-2020.mdx" /* webpackChunkName: "component---src-pages-blog-2020-september-where-can-you-find-cheap-tv-rates-in-2020-mdx" */),
  "component---src-pages-blog-jsx": () => import("./../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-celular-compara-telefonos-moviles-mdx": () => import("./../src/pages/celular/compara-teléfonos-móviles.mdx" /* webpackChunkName: "component---src-pages-celular-compara-telefonos-moviles-mdx" */),
  "component---src-pages-celular-compare-cell-plans-mdx": () => import("./../src/pages/celular/compare-cell-plans.mdx" /* webpackChunkName: "component---src-pages-celular-compare-cell-plans-mdx" */),
  "component---src-pages-contact-us-jsx": () => import("./../src/pages/contact-us.jsx" /* webpackChunkName: "component---src-pages-contact-us-jsx" */),
  "component---src-pages-empresa-compare-business-banking-services-mdx": () => import("./../src/pages/empresa/compare-business-banking-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-banking-services-mdx" */),
  "component---src-pages-empresa-compare-business-credit-cards-mdx": () => import("./../src/pages/empresa/compare-business-credit-cards.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-credit-cards-mdx" */),
  "component---src-pages-empresa-compare-business-credit-services-mdx": () => import("./../src/pages/empresa/compare-business-credit-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-credit-services-mdx" */),
  "component---src-pages-empresa-compare-business-email-marketing-services-mdx": () => import("./../src/pages/empresa/compare-business-email-marketing-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-email-marketing-services-mdx" */),
  "component---src-pages-empresa-compare-business-insurance-products-mdx": () => import("./../src/pages/empresa/compare-business-insurance-products.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-insurance-products-mdx" */),
  "component---src-pages-empresa-compare-business-loans-mdx": () => import("./../src/pages/empresa/compare-business-loans.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-loans-mdx" */),
  "component---src-pages-empresa-compare-business-password-management-mdx": () => import("./../src/pages/empresa/compare-business-password-management.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-password-management-mdx" */),
  "component---src-pages-empresa-compare-business-payroll-software-mdx": () => import("./../src/pages/empresa/compare-business-payroll-software.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-payroll-software-mdx" */),
  "component---src-pages-empresa-compare-business-scheduling-services-mdx": () => import("./../src/pages/empresa/compare-business-scheduling-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-business-scheduling-services-mdx" */),
  "component---src-pages-empresa-compare-voip-services-mdx": () => import("./../src/pages/empresa/compare-voip-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-voip-services-mdx" */),
  "component---src-pages-empresa-compare-web-domain-management-services-mdx": () => import("./../src/pages/empresa/compare-web-domain-management-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-web-domain-management-services-mdx" */),
  "component---src-pages-empresa-compare-web-hosting-services-mdx": () => import("./../src/pages/empresa/compare-web-hosting-services.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-web-hosting-services-mdx" */),
  "component---src-pages-empresa-compare-web-site-builder-tools-mdx": () => import("./../src/pages/empresa/compare-web-site-builder-tools.mdx" /* webpackChunkName: "component---src-pages-empresa-compare-web-site-builder-tools-mdx" */),
  "component---src-pages-finanzas-compare-identity-protection-service-mdx": () => import("./../src/pages/finanzas/compare-identity-protection-service.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-identity-protection-service-mdx" */),
  "component---src-pages-finanzas-compare-personal-banking-services-mdx": () => import("./../src/pages/finanzas/compare-personal-banking-services.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-banking-services-mdx" */),
  "component---src-pages-finanzas-compare-personal-budgeting-tools-mdx": () => import("./../src/pages/finanzas/compare-personal-budgeting-tools.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-budgeting-tools-mdx" */),
  "component---src-pages-finanzas-compare-personal-credit-cards-mdx": () => import("./../src/pages/finanzas/compare-personal-credit-cards.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-credit-cards-mdx" */),
  "component---src-pages-finanzas-compare-personal-credit-monitoring-services-mdx": () => import("./../src/pages/finanzas/compare-personal-credit-monitoring-services.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-credit-monitoring-services-mdx" */),
  "component---src-pages-finanzas-compare-personal-crypto-currency-services-mdx": () => import("./../src/pages/finanzas/compare-personal-crypto-currency-services.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-crypto-currency-services-mdx" */),
  "component---src-pages-finanzas-compare-personal-debt-services-mdx": () => import("./../src/pages/finanzas/compare-personal-debt-services.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-debt-services-mdx" */),
  "component---src-pages-finanzas-compare-personal-tax-services-mdx": () => import("./../src/pages/finanzas/compare-personal-tax-services.mdx" /* webpackChunkName: "component---src-pages-finanzas-compare-personal-tax-services-mdx" */),
  "component---src-pages-hogar-compara-paquetes-de-tv-mdx": () => import("./../src/pages/hogar/compara-paquetes-de-tv.mdx" /* webpackChunkName: "component---src-pages-hogar-compara-paquetes-de-tv-mdx" */),
  "component---src-pages-hogar-compara-proveedores-de-servicio-de-internet-domestico-mdx": () => import("./../src/pages/hogar/compara-proveedores-de-servicio-de-Internet-doméstico.mdx" /* webpackChunkName: "component---src-pages-hogar-compara-proveedores-de-servicio-de-internet-domestico-mdx" */),
  "component---src-pages-hogar-compare-home-cleaning-services-mdx": () => import("./../src/pages/hogar/compare-home-cleaning-services.mdx" /* webpackChunkName: "component---src-pages-hogar-compare-home-cleaning-services-mdx" */),
  "component---src-pages-hogar-compare-home-security-products-mdx": () => import("./../src/pages/hogar/compare-home-security-products.mdx" /* webpackChunkName: "component---src-pages-hogar-compare-home-security-products-mdx" */),
  "component---src-pages-hogar-compare-password-managers-mdx": () => import("./../src/pages/hogar/compare-password-managers.mdx" /* webpackChunkName: "component---src-pages-hogar-compare-password-managers-mdx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-contact-us-jsx": () => import("./../src/pages/partners-contact-us.jsx" /* webpackChunkName: "component---src-pages-partners-contact-us-jsx" */),
  "component---src-pages-premium-jsx": () => import("./../src/pages/premium.jsx" /* webpackChunkName: "component---src-pages-premium-jsx" */),
  "component---src-pages-prestamos-comparar-productos-de-prestamo-para-auto-mdx": () => import("./../src/pages/préstamos/comparar-productos-de-préstamo-para-auto.mdx" /* webpackChunkName: "component---src-pages-prestamos-comparar-productos-de-prestamo-para-auto-mdx" */),
  "component---src-pages-prestamos-compare-home-loans-mdx": () => import("./../src/pages/préstamos/compare-home-loans.mdx" /* webpackChunkName: "component---src-pages-prestamos-compare-home-loans-mdx" */),
  "component---src-pages-prestamos-compare-mortgage-refinancing-loans-mdx": () => import("./../src/pages/préstamos/compare-mortgage-refinancing-loans.mdx" /* webpackChunkName: "component---src-pages-prestamos-compare-mortgage-refinancing-loans-mdx" */),
  "component---src-pages-prestamos-compare-personal-loans-mdx": () => import("./../src/pages/préstamos/compare-personal-loans.mdx" /* webpackChunkName: "component---src-pages-prestamos-compare-personal-loans-mdx" */),
  "component---src-pages-prestamos-compare-student-loan-refinancing-mdx": () => import("./../src/pages/préstamos/compare-student-loan-refinancing.mdx" /* webpackChunkName: "component---src-pages-prestamos-compare-student-loan-refinancing-mdx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-seguro-compara-proveedores-de-seguros-de-auto-mdx": () => import("./../src/pages/seguro/compara-proveedores-de-seguros-de-auto.mdx" /* webpackChunkName: "component---src-pages-seguro-compara-proveedores-de-seguros-de-auto-mdx" */),
  "component---src-pages-seguro-compare-health-insurance-products-mdx": () => import("./../src/pages/seguro/compare-health-insurance-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-health-insurance-products-mdx" */),
  "component---src-pages-seguro-compare-home-insurance-products-mdx": () => import("./../src/pages/seguro/compare-home-insurance-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-home-insurance-products-mdx" */),
  "component---src-pages-seguro-compare-life-insurance-products-mdx": () => import("./../src/pages/seguro/compare-life-insurance-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-life-insurance-products-mdx" */),
  "component---src-pages-seguro-compare-medicare-products-mdx": () => import("./../src/pages/seguro/compare-medicare-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-medicare-products-mdx" */),
  "component---src-pages-seguro-compare-motorcycle-insurance-products-mdx": () => import("./../src/pages/seguro/compare-motorcycle-insurance-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-motorcycle-insurance-products-mdx" */),
  "component---src-pages-seguro-compare-renters-insurance-products-mdx": () => import("./../src/pages/seguro/compare-renters-insurance-products.mdx" /* webpackChunkName: "component---src-pages-seguro-compare-renters-insurance-products-mdx" */),
  "component---src-pages-sitemap-jsx": () => import("./../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-utilidades-comparando-tarifas-electricas-mdx": () => import("./../src/pages/utilidades/comparando-tarifas-eléctricas.mdx" /* webpackChunkName: "component---src-pages-utilidades-comparando-tarifas-electricas-mdx" */),
  "component---src-pages-utilidades-compare-gas-rates-mdx": () => import("./../src/pages/utilidades/compare-gas-rates.mdx" /* webpackChunkName: "component---src-pages-utilidades-compare-gas-rates-mdx" */)
}

