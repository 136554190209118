import React from "react";

export default (props) => {
  const { willBeVisible, columnConfiguration, columnHeaderConfiguration, className, parentGridMetaData } = props;
  const isFirstDataColumn = willBeVisible && columnConfiguration.index === parentGridMetaData.dataColumnIndexes.firstDataColumnIndex;
  const isLastDataColumn = willBeVisible && columnConfiguration.index === parentGridMetaData.dataColumnIndexes.lastDataColumnIndex;

  return (
    <div
      className={`${className} px-2 pt-4 bg-transparent text-center h-24 flex flex-col items-center justify-center ${isFirstDataColumn ? `rounded-tl` : ``} ${isLastDataColumn ? `rounded-tr` : ``}`}
    >
      {props.children}
      <span className="py-4 text-xs font-bold">{columnConfiguration.isComingSoon ? "Coming Soon" : columnHeaderConfiguration.heading.text}</span>
    </div>
  );
};
