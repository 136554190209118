import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import WebManagementIllustration from "../../../../svg/ComparisonResultPageIllustrations/webdomainmanagement.svg";
import DomainComSVG from "../../../../svg/ComparisonResultGridLogos/webdomainmanagement/domaincom.svg";
import NameCheapSVG from "../../../../svg/ComparisonResultGridLogos/webdomainmanagement/namecheap.svg";
import OneAndOneSVG from "../../../../svg/ComparisonResultGridLogos/webdomainmanagement/oneandone.svg";
import GoDaddySVG from "../../../../svg/ComparisonResultGridLogos/webdomainmanagement/godaddy.svg";
import NetworkSolutionsSVG from "../../../../svg/ComparisonResultGridLogos/webdomainmanagement/networksolutions.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  WebManagementIllustration,
  DomainComSVG,
  NameCheapSVG,
  OneAndOneSVG,
  GoDaddySVG,
  NetworkSolutionsSVG,
  React
};