import React from "react";

import BusinessSVG from "../../svg/LandingPageProductCategories/businessicon.svg";
import CellSVG from "../../svg/LandingPageProductCategories/cellicon.svg";
import FinancialSVG from "../../svg/LandingPageProductCategories/financialicon.svg";
import HomeSVG from "../../svg/LandingPageProductCategories/homeicon.svg";
import InsuranceSVG from "../../svg/LandingPageProductCategories/insuranceicon.svg";
import LoanSVG from "../../svg/LandingPageProductCategories/loanicon.svg";
import UtilitiesSvg from "../../svg/LandingPageProductCategories/utilityicon.svg";

import NavBusinessSvg from "../../svg/navbar/businessicon.svg";
import NavCellSVG from "../../svg/navbar/cellicon.svg";
import NavFinancialSVG from "../../svg/navbar/financialicon.svg";
import NavHomeSVG from "../../svg/navbar/homeicon.svg";
import NavInsuranceSVG from "../../svg/navbar/insuranceicon.svg";
import NavLoanSVG from "../../svg/navbar/loanicon.svg";
import NavUtilitiesSvg from "../../svg/navbar/utilityicon.svg";

const navBarIconStyle = "inline-block align-middle w-4 h-4 pr-1";

export default {
  productCategoryConfigurations: [
    {
      category: "empresa",
      landingPageIcon: <BusinessSVG className="block mx-auto" />,
      navbarIcon: <NavBusinessSvg className={navBarIconStyle}/>,
      subText: "Productos técnicos y financieros que ayudarán a prosperar a tu pequeña empresa",
      premiumSubCatagories: [
        "business insurance",
        "business credit services",
        "business insurance",
        "business password management",
        "email marketing services",
        "scheduling services",
        "web domain management",
        "website creation tools",
        "website creation tools",
      ],
    },
    {
      category: "celular",
      landingPageIcon: <CellSVG className="block mx-auto" />,
      navbarIcon: <NavCellSVG className={navBarIconStyle}/>,
      subText: "Find a new handset with savings on the latest phones",
      premiumSubCatagories: [],
    },
    {
      category: "finanzas",
      landingPageIcon: <FinancialSVG className="block mx-auto" />,
      navbarIcon: <NavFinancialSVG className={navBarIconStyle}/>,
      subText: "Great products to manage your money and your credit score ",
      premiumSubCatagories: ["cryptocurrency services"],
    },
    {
      category: "hogar",
      landingPageIcon: <HomeSVG className="block mx-auto" />,
      navbarIcon: <NavHomeSVG className={navBarIconStyle}/>,
      subText: "Keep your home safe and protected with out home services ",
      premiumSubCatagories: ["home cleaning services", "password managers"],
    },
    {
      category: "seguro",
      landingPageIcon: <InsuranceSVG className="block mx-auto" />,
      navbarIcon: <NavInsuranceSVG className={navBarIconStyle}/>,
      subText: "Ensure all that you value is protected against accidents",
      premiumSubCatagories: ["health insurance", "medicare insurance", "motorcycle insurance"],
    },
    {
      category: "préstamos",
      landingPageIcon: <LoanSVG className="block mx-auto" />,
      navbarIcon: <NavLoanSVG className={navBarIconStyle}/>,
      subText: "Find the right loan product for your needs",
      premiumSubCatagories: [],
    },
    {
      category: "utilidades",
      landingPageIcon: <UtilitiesSvg className="block mx-auto" />,
      navbarIcon: <NavUtilitiesSvg className={navBarIconStyle}/>,
      subText: "Get a better rate for your core utilities",
      premiumSubCatagories: ["gas rates"],
    },
  ],
};
