import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import HomeProtectionIllustration from "../../../../svg/ComparisonResultPageIllustrations/homesecurityproducts.svg";
import ScoutSVG from "../../../../svg/ComparisonResultGridLogos/homesecurity/scout.svg";
import DeepSentinelSVG from "../../../../svg/ComparisonResultGridLogos/homesecurity/deepsentinel.svg";
import SimpliSafeSVG from "../../../../svg/ComparisonResultGridLogos/homesecurity/simplisafe.svg";
import AdtSVG from "../../../../svg/ComparisonResultGridLogos/homesecurity/adt.svg";
import VivintSVG from "../../../../svg/ComparisonResultGridLogos/homesecurity/vivint.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  HomeProtectionIllustration,
  ScoutSVG,
  DeepSentinelSVG,
  SimpliSafeSVG,
  AdtSVG,
  VivintSVG,
  React
};