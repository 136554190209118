import React from "react";
import * as blogService from "../../services/BlogService";
import BlogIndexPopularArticle from "../BlogIndexPopularArticle";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

/**
 * Container element that generates a list of blog posts from the provided data.
 *
 * @param {Array} blogPostData An array of blog post items.
 * @param {string} blogContentCategory The blog category to filter the data on as specified by the parent container.
 */
const ComparisonResultPageBlogPostContainer = (props) => {
  const { blogPostData, blogContentCategory } = props;
  const filteredPopularArticles = blogService.getFilteredArticlesUsing(blogPostData, blogContentCategory);

  return (
    <>
      <p className="pt-4 text-blue-500">Artículos relacionados</p>
      <ul className="px-0 pt-0 text-gray-500">
        {filteredPopularArticles.map((articleData) => (
          <BlogIndexPopularArticle key={uuidv4()} articleData={articleData} />
        ))}
      </ul>
    </>
  );
};

ComparisonResultPageBlogPostContainer.propTypes = {
  blogPostData: PropTypes.array.isRequired,
  blogContentCategory: PropTypes.string.isRequired,
};

export default ComparisonResultPageBlogPostContainer;
