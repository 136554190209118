import React from "react";
import { FolderOpenOutlined, CalendarOutlined } from "@ant-design/icons";
import moment from "moment";
import PropTypes from "prop-types";

/**
 * Renders a div element that displays a blog posts meta data as supplied by the posts frontmatter.
 *
 * @param {Props} props Array of data passed to the function from the parent component and the rendering pipeline.
 */
const BlogPostMetaData = (props) => {
  const { category, postDate } = props;
  const articleDateFormat = "MMMM DD, YYYY";

  return (
    <div className="px-2 py-1 text-blue-500 ">
      <span className="">
        <CalendarOutlined className="py-1 pr-1 align-top" />
        {moment(postDate).format(articleDateFormat)}
      </span>
      <span className="ml-3">
        <FolderOpenOutlined className="px-1 py-1 align-top" />
        {category}
      </span>
    </div>
  );
};

BlogPostMetaData.propTypes = {
  postDate: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

BlogPostMetaData.defaultProps = {
  postDate: new Date().toLocaleDateString(),
  category: "Blog Posts",
};

export default BlogPostMetaData;
