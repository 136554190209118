import React from "react";
import ComparisonResultGridLinkButton from "../ComparisonResultGridLinkButton";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { dataColumnsConfiguration, renderMode } = props;
  const comingSoonContainer = <div className="inline-block w-full p-2 text-center lg:w-1/5"></div>;

  return (
    <div className="flex flex-wrap w-full">
      <div className={`${renderMode.toLowerCase() === "row" ? `block` : `hidden`} lg:w-1/4`}>&nbsp;</div>
      <div className="w-full pt-2 lg:w-3/4">
        {dataColumnsConfiguration.map((column) => {
          return column.isComingSoon ? comingSoonContainer : <ComparisonResultGridLinkButton key={uuidv4()} claimButtonConfiguration={column.claimButtonConfiguration} />;
        })}
      </div>
    </div>
  );
};
