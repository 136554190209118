import React from "react";
import PropTypes from "prop-types";

/**
 * Renders a container used to apply styling to the main content of the ComparisonResultColumNContent component.
 *
 * @param {Array} children An array of elements to render within the container element.
 * @returns {element} A div element that renders the child elements within while applying styling to the container.
 */
const ComparisonResultColumnContentBackgroundContainer = (props) => {
  return <div className="p-3 bg-blue-100">{props.children}</div>;
};

ComparisonResultColumnContentBackgroundContainer.propTypes = {
  children: PropTypes.array,
};

export default ComparisonResultColumnContentBackgroundContainer;
