import moment from "moment";

// eslint-disable-next-line no-unused-vars
import { window } from "browser-monads";

/**
 * Determines a valid array with a non zero length
 *
 * @param {Array} obj The array object to validate.
 */
const isValid = (obj) => {
  return obj && obj.length > 0;
};

/**
 *
 * @param {*} edges
 */
export const getBlogPostCategoryDataFrom = (edges) => {
  if (isValid(edges)) {
    return edges.map((e) => e.node.fields);
  }

  throw new Error("Null or empty edges array, unable to extract blog post data");
};

/**
 *
 * @param {*} blogPostData
 */
export const getGroupedBlogPostCategoryDataFrom = (blogPostData) => {
  if (!isValid(blogPostData)) {
    throw new Error("Null or empty blog post array, unable to extract Grouped Blog Category data");
  }

  var categoryGroupedData = [];
  blogPostData.reduce(function (result, post) {
    if (!result[post.blogCategory]) {
      result[post.blogCategory] = { category: post.blogCategory, numberOfPosts: 0 };
      categoryGroupedData.push(result[post.blogCategory]);
    }
    result[post.blogCategory].numberOfPosts += 1;
    return result;
  }, {});

  categoryGroupedData = categoryGroupedData.sort((a, b) => {
    return a.category > b.category ? 1 : b.category > a.category ? -1 : 0;
  });

  return categoryGroupedData;
};

/**
 *
 * @param {*} blogPost
 */
const getMonthYearPublishedFrom = (blogPost) => {
  if (!blogPost) {
    throw new Error("Null or empty blogPost object, unable to get published month and year");
  }

  const DATE_FORMAT = "MMMM YYYY";
  return moment(blogPost.blogDate).format(DATE_FORMAT);
};

/**
 *
 * @param {*} blogPost
 */
const getFirstOfMonthYearPublishedFrom = (blogPost) => {
  if (!blogPost) {
    throw new Error("Null or empty blogPost object, unable to get the first of the published month and year");
  }

  const DATE_FORMAT = "01/MM/YYYY";
  return moment(blogPost.blogDate).format(DATE_FORMAT);
};

/**
 *
 * @param {*} blogPostData
 */
export const getGroupedBlogPostArchiveDataFrom = (blogPostData) => {
  if (!isValid(blogPostData)) {
    throw new Error("Null or empty menus array, unable to extract Grouped Blog Archive data");
  }

  var archivedGroupedData = [];
  blogPostData.reduce(function (result, post) {
    const postMonthYearDate = getMonthYearPublishedFrom(post);
    const archiveSortingDate = getFirstOfMonthYearPublishedFrom(post);

    if (!result[postMonthYearDate]) {
      result[postMonthYearDate] = { archiveDate: postMonthYearDate, archiveSortingDate: archiveSortingDate, numberOfPosts: 0 };
      archivedGroupedData.push(result[postMonthYearDate]);
    }
    result[postMonthYearDate].numberOfPosts += 1;
    return result;
  }, {});

  archivedGroupedData = archivedGroupedData.sort((a, b) => {
    return new Date(a.archiveSortingDate) - new Date(b.archiveSortingDate);
  });

  return archivedGroupedData;
};

/**
 * Generates a random, 3 element, array which contains links to blog posts.
 *
 * This is randomly generated as a temporary way to show content until we measure real popularity.
 *
 * @param {Array} blogPostData An array of items containing the data generated from the MDX Blog posts.
 */
export const getFilteredPopularArticlesUsing = (blogPostData) => {
  if (!isValid(blogPostData)) {
    throw new Error("Null or empty blog post data array, unable to generate popular articles");
  }

  const sortedBlogPostData = blogPostData.sort((a, b) => {
    return new Date(b.blogDate) - new Date(a.blogDate);
  });

  let popularPosts = [];

  do {
    const randomIndex = Math.floor(Math.random() * sortedBlogPostData.length);
    const randomPost = sortedBlogPostData[randomIndex];

    if (popularPosts.indexOf(randomPost) === -1) {
      popularPosts.push(randomPost);
    }
  } while (popularPosts.length <= 2);

  return popularPosts;
};

/**
 *
 * @param {*} blogPostData
 * @param {*} searchParams
 */
export const getFilteredBlogPostDataUsing = (blogPostData, searchParams) => {
  if (!isValid(blogPostData)) {
    throw new Error("Null or empty blog post data array, unable to filter articles");
  }

  const { searchContext, searchTerm } = searchParams;
  const searchContextType = searchContext.toLowerCase();

  if (searchContextType === "no-filter") {
    return blogPostData;
  }

  if (searchContextType === "text-search") {
    return blogPostData.filter((post) => post.blogTitle.toLowerCase().includes(searchTerm.toLowerCase()));
  }

  if (searchContextType === "category-search") {
    return blogPostData.filter((post) => post.blogCategory.toLowerCase() === searchTerm.toLowerCase());
  }

  if (searchContextType === "archive-search") {
    return blogPostData.filter((post) => {
      const postArchiveDate = getMonthYearPublishedFrom(post);
      return postArchiveDate === searchTerm;
    });
  }

  return blogPostData;
};

/**
 *
 * @param {*} window
 */
export const getInitialSearchParamsUsing = (window) => {
  const defaultSearchParams = getDefaultSearchParams();

  if (window && window.history.state) {
    return window.history.state.searchParams ?? defaultSearchParams;
  }

  return defaultSearchParams;
};

/**
 *
 */
export const getDefaultSearchParams = () => {
  return { searchContext: "no-filter", searchTerm: "" };
};

/**
 * Generates an array of 3 blog posts based on the provided blog post data. The content category can be used to give
 * precedence to a blog category.
 *
 * @param {Array} blogPostData Contains the structured blog post data.
 * @param {string} blogContentCategory Contains the name specified by the caller to indicate the related content category.
 * @returns {Array} Containing 3 blog post items. If there are not 3 blog posts for the specified content category the results will be filled with latest posts.
 */
export const getFilteredArticlesUsing = (blogPostData, blogContentCategory) => {
  if (!isValid(blogPostData)) {
    throw new Error("Null or empty blog post data array, unable to filter popular articles");
  }

  let blogPosts = blogPostData.filter((blogPost) => blogPost.blogCategory.toLowerCase() === blogContentCategory.toLowerCase());

  const MINIMUM_POSTS = 3;
  if (blogPosts.length >= MINIMUM_POSTS) {
    blogPosts
      .sort((a, b) => {
        return new Date(b.blogDate) - new Date(a.blogDate);
      })
      .slice(0, 2);
  }

  const additionalPosts = MINIMUM_POSTS - blogPosts.length;

  if (additionalPosts > 0) {
    const nonCategoryContent = blogPostData.filter((blogPost) => blogPost.blogCategory.toLowerCase() !== blogContentCategory).slice(0, additionalPosts);

    if (nonCategoryContent.length > 0) {
      return blogPosts.concat(nonCategoryContent);
    }
  }

  return blogPosts.length >= 3 ? blogPosts.slice(0, 3) : blogPosts;
};
