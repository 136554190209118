import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import CryptoIllustration from "../../../../svg/ComparisonResultPageIllustrations/cryptocurrencyservices.svg";
import CoinBaseSVG from "../../../../svg/ComparisonResultGridLogos/cryptoservices/coinbase.svg";
import TenXSVG from "../../../../svg/ComparisonResultGridLogos/cryptoservices/tenx.svg";
import XcoinsSVG from "../../../../svg/ComparisonResultGridLogos/cryptoservices/xcoins.svg";
import CryptoComSVG from "../../../../svg/ComparisonResultGridLogos/cryptoservices/crypto.svg";
import WirexSVG from "../../../../svg/ComparisonResultGridLogos/cryptoservices/wirex.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  CryptoIllustration,
  CoinBaseSVG,
  TenXSVG,
  XcoinsSVG,
  CryptoComSVG,
  WirexSVG,
  React
};