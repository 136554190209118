import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import CarLoanIllustration from "../../../../svg/ComparisonResultPageIllustrations/carloans.svg";
import CCUSVG from "../../../../svg/ComparisonResultGridLogos/carloans/consumercreditunion.svg";
import BoroSVG from "../../../../svg/ComparisonResultGridLogos/carloans/boro.svg";
import CapitalOneSVG from "../../../../svg/ComparisonResultGridLogos/carloans/capitalone.svg";
import LightStreamSVG from "../../../../svg/ComparisonResultGridLogos/carloans/lightstream.svg";
import CarvanaSVG from "../../../../svg/ComparisonResultGridLogos/carloans/carvana.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  CarLoanIllustration,
  CCUSVG,
  BoroSVG,
  CapitalOneSVG,
  LightStreamSVG,
  CarvanaSVG,
  React
};