import React from "react";
import FilledStarSvg from "../../../svg/starfill.svg";
import EmptyStarSvg from "../../../svg/starempty.svg";
import * as ratingUtils from "../../utils/ratingUtils";
import * as priceCellUtils from "./comparisonResultColumnPriceCellUtils";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { row, parentColumnConfiguration } = props;
  const { isLabelledColumn, columnLabel, isComingSoon } = parentColumnConfiguration;
  const { disableSavingStrikeThrough } = row;

  if (isComingSoon) {
    return (
      <p className={props.className}>
        <span className="block w-full h-full text-gray-500">
          <span className="block w-full my-2 text-xl">Coming Soon</span>
          <span className="block w-full text-xs text-blue-500">We are expanding our reach and will have additional products here to see really soon.</span>
        </span>
      </p>
    );
  }

  return (
    <p className={props.className}>
      <span className="block w-full h-full text-gray-500">
        <span className={`${isLabelledColumn ? `block` : `hidden`} w-full -mt-px lg:-mt-2 text-center`}>
          <span className="px-3 py-1 text-xs text-gray-500 bg-yellow-500 rounded-bl rounded-br">{columnLabel}</span>
        </span>

        <span className={`${row.showSavingText ? `block` : `hidden`} text-gray-300 ${isLabelledColumn ? `mt-1 lg:-mt-2` : `mt-2`} ${disableSavingStrikeThrough ? `` : `line-through`} text-right pr-4`}>
          {row.savingText}
        </span>
        <span className={`${row.showSavingText ? `hidden` : `block`} text-gray-300 ${isLabelledColumn ? `mt-1 lg:-mt-2` : `mt-2`}  text-right pr-4`}>&nbsp;</span>

        {priceCellUtils.getFormattedPriceFor(row)}

        <span className={`${row.showRating ? `block` : `hidden`} text-center w-full`}>
          {ratingUtils.createRatingArrayFrom(row.rating).map((r) => {
            if (r === 1) {
              return <FilledStarSvg key={uuidv4()} className="inline-flex" />;
            } else {
              return <EmptyStarSvg key={uuidv4()} className="inline-flex" />;
            }
          })}
        </span>
      </span>
    </p>
  );
};
