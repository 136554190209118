import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import InternetIllustration from "../../../../svg/ComparisonResultPageIllustrations/homeinternetservices.svg";
import RiseSVG from "../../../../svg/ComparisonResultGridLogos/homeinternet/risebroadband.svg";
import SuddenLinkSVG from "../../../../svg/ComparisonResultGridLogos/homeinternet/suddenlink.svg";
import HughesNetSVG from "../../../../svg/ComparisonResultGridLogos/homeinternet/hughesnet.svg";
import ATTSVG from "../../../../svg/ComparisonResultGridLogos/homeinternet/att.svg";
import ViasatSVG from "../../../../svg/ComparisonResultGridLogos/homeinternet/viasat.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  InternetIllustration,
  RiseSVG,
  SuddenLinkSVG,
  HughesNetSVG,
  ATTSVG,
  ViasatSVG,
  React
};