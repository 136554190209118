import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import CreditCardIllustration from "../../../../svg/ComparisonResultPageIllustrations/personalcreditcards.svg";
import DiscoverSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditcards/discover.svg";
import AmericanExpressSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditcards/americanexpress.svg";
import USBankSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditcards/usbank.svg";
import CitiSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditcards/citibank.svg";
import ChaseSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditcards/chasebank.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  CreditCardIllustration,
  DiscoverSVG,
  AmericanExpressSVG,
  USBankSVG,
  CitiSVG,
  ChaseSVG,
  React
};