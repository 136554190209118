import React from "react";
import { Avatar } from "antd";
import * as StatusMenuUtils from "../../utils/StatusMenuUtils";

export default (props) => {
  return (
    <>
      <div className="flex flex-row flex-1">
        <div className="flex-col flex-1 py-2 pl-2">
          <Avatar shape="square" size={40} className="text-xl text-white bg-blue-500 border border-blue-500 lg:pl-0" src={`${props.photoURL ? props.photoURL : ``}`}>
            {StatusMenuUtils.getAvatarCharacterFrom(props.name)}
          </Avatar>
        </div>
        <div className="flex-col flex-grow px-4 py-2">
          {props.name.split(" ").map((name) => (
            <p className="pt-4 text-blue-900">{name}</p>
          ))}
          <p className="pt-4 text-xs text-blue-500">{props.email}</p>
        </div>
      </div>
    </>
  );
};
