import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import BudgetingIllustration from "../../../../svg/ComparisonResultPageIllustrations/personalbudgetingtools.svg";
import SplashFinancialLogo from "../../../../svg/supplier-color-logos/SplashFinancial.svg";
import EarnestLogo from "../../../../svg/supplier-color-logos/Earnest.svg";
import MintSVG from "../../../../svg/ComparisonResultGridLogos/personalbudgeting/mint.svg";
import YNABSVG from "../../../../svg/ComparisonResultGridLogos/personalbudgeting/ynab.svg";
import WismoSVG from "../../../../svg/ComparisonResultGridLogos/personalbudgeting/wismo.svg";
import GoodBudgetSVG from "../../../../svg/ComparisonResultGridLogos/personalbudgeting/goodbudget.svg";
import PocketGuardSVG from "../../../../svg/ComparisonResultGridLogos/personalbudgeting/pocketguard.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  BudgetingIllustration,
  SplashFinancialLogo,
  EarnestLogo,
  MintSVG,
  YNABSVG,
  WismoSVG,
  GoodBudgetSVG,
  PocketGuardSVG,
  React
};