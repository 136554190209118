export const getColumnHeaderConfigurationFrom = (dataRows) => {
  return dataRows.find((r) => r.isHeadingCell === true);
};

export const shouldApplyBackgroundTo = (row) => {
  if (!row) {
    throw new Error("Null or Empty row, unable to determine if it is even or not");
  }

  return parseInt(row.index) % 2 === 0;
};

export const isVisible = (renderMode) => {
  if (!renderMode) {
    throw new Error("Null renderMode, unable to get determine render mode");
  }

  return renderMode.toLowerCase() === "visible" || false;
};

export const getColumnRowConfigurationUsing = (columnConfiguration, renderMode) => {
  if (!columnConfiguration) {
    throw new Error("Null or Empty column configuration, unable to get data rows");
  }

  const dataRows = isVisible(renderMode) ? columnConfiguration.rows : columnConfiguration.expansionRows;

  return dataRows
    .filter((r) => r.isHeadingCell !== true)
    .sort((a, b) => {
      return a.indx - b.index;
    });
};

export const getHeaderCellVisibilityFrom = (parentGridMetaData) => {
  if (!parentGridMetaData) return true;

  return parentGridMetaData.showHeaderRow;
};
