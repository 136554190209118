import React from "react";
import { Carousel } from "antd";
import ComparisonPageRecommendation from "../ComparisonPageRecommendation";
import * as arrayUtils from "../../utils//ArrayUtils";
import * as comparisonPageRecommendationsUtils from "./comparisonPageRecommendationsUtils";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import recommendedProductData from "../../data/recommendedProductsData";
import productCategoryConfigurationData from "../../data/productCategoryConfigurationData";
import useNavigationData from "../../hooks/useNavigationData";
import { v4 as uuidv4 } from "uuid";

/**
 * Generates a div containing the Right arrow button for the carousel to allow the user to move to the next
 * batch of Recommended products.
 *
 * @param {string} className A passthrough mapping to the generated elements className prop.
 * @param {string} style A passthrough mapping to the generated elements style prop.
 * @param {Event} onClick A passthrough mapping to the generated elements onClick event handler.
 * @returns {Element} A div configured with the icon to render for moving to the next Recommended Product set.
 */
const MoveNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      key={uuidv4()}
      style={{
        ...style,
        color: "#849dae",
        fontSize: "15px",
        lineHeight: "1.5715",
        // top: "295px",
        bottom: "25px",
        right: "0px",
      }}
      onClick={onClick}
    >
      <RightOutlined />
    </button>
  );
};

/**
 * Generates a div containing the Left arrow button for the carousel to allow the user to move to the previous
 * batch of Recommended products.
 *
 * @param {string} className A passthrough mapping to the generated elements className prop.
 * @param {string} style A passthrough mapping to the generated elements style prop.
 * @param {Event} onClick A passthrough mapping to the generated elements onClick event handler.
 * @returns {Element} A div configured with the icon to render for moving to the previous Recommended Product set.
 */
const MovePreviousArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      key={uuidv4()}
      style={{
        ...style,
        color: "#849dae",
        fontSize: "15px",
        lineHeight: "1.5715",
        bottom: "25px",
        left: "-2px",
        zIndex: "10",
      }}
      onClick={onClick}
    >
      <LeftOutlined />
    </button>
  );
};

/**
 * Generates an element that configures and populates an Antd Carousel with data to render
 * the Recommended Product tiles.
 *
 * @param {string} requestingProductCategory  A string containing the name of the page that wants to render the product product recommendations.
 * @returns {element} A element which generates the Recommended Products carousel.
 */
const ComparisonPageRecommendations = (props) => {
  const navigationData = useNavigationData();

  const { requestingProductCategory } = props;
  const { productCategoryConfigurations } = productCategoryConfigurationData;
  const { recommendedProductConfigurations } = recommendedProductData;

  const requestedRecommendedProductConfigurations = comparisonPageRecommendationsUtils.getRecommendedProductConfigurationsUsing(
    requestingProductCategory,
    recommendedProductConfigurations,
    productCategoryConfigurations,
    navigationData.menuData
  );

  const groupedRelatedProductCategories = arrayUtils.chunk(requestedRecommendedProductConfigurations, 3);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <div className={``}>
      <Carousel
        autoplaySpeed={8000}
        arrows={requestedRecommendedProductConfigurations.length > 3}
        autoplay={requestedRecommendedProductConfigurations.length > 3}
        prevArrow={<MovePreviousArrow />}
        nextArrow={<MoveNextArrow />}
      >
        {groupedRelatedProductCategories.map((relatedProductCategoryGroup) => (
          <div key={uuidv4()}>
            {relatedProductCategoryGroup.map((relatedProductCategory) => (
              <ComparisonPageRecommendation key={uuidv4()} configuration={relatedProductCategory}></ComparisonPageRecommendation>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

ComparisonPageRecommendations.propTypes = {
  requestingProductCategory: PropTypes.string.isRequired,
};

export default ComparisonPageRecommendations;
