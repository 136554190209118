import React from "react";
import ComparisonResultGridRowLabelColumn from "../ComparisonResultGridRowLabelColumn";
import ComparisonResultColumn from "../ComparisonResultColumn";
import ComparisonResultGridExpander from "../ComparisonResultGridExpander";
import ComparisonResultGridLinkButtons from "../ComparisonResultGridLinkButtons";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { labelColumnConfiguration, dataColumnsConfiguration, gridImageMap, gridMetaData, renderExpander } = props;

  return (
    <main>
      <div className="container mx-auto -mt-56 font-body">
        <div className="flex flex-wrap overflow-hidden">
          <div className="w-full overflow-hidden xl:w-1/12"></div>
          <div className="flex flex-wrap w-full overflow-hidden xl:w-10/12">
            <ComparisonResultGridRowLabelColumn key={uuidv4()} columnConfiguration={labelColumnConfiguration} renderMode="visible" />
            <div className="flex flex-wrap w-full rounded lg:w-3/4">
              {dataColumnsConfiguration.map((column) => (
                <ComparisonResultColumn key={uuidv4()} columnConfiguration={column} imageMap={gridImageMap} renderMode="visible" parentGridMetaData={gridMetaData} />
              ))}
            </div>
            <ComparisonResultGridExpander
              key={uuidv4()}
              labelColumnConfiguration={labelColumnConfiguration}
              dataColumnsConfiguration={dataColumnsConfiguration}
              gridImageMap={gridImageMap}
              renderExpander={renderExpander}
            />
            <ComparisonResultGridLinkButtons key={uuidv4()} renderMode="row" dataColumnsConfiguration={dataColumnsConfiguration} />
          </div>
          <div className="w-full overflow-hidden xl:w-1/12"></div>
        </div>
      </div>
    </main>
  );
};
