import React, { useContext } from "react";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { images, children } = props;
  const windowSize = useContext(WindowSizeContext);
  const largeViewImageElement = images.find((image) => image.props.imagekey === "large-view");
  const smallViewImageElement = images.find((image) => image.props.imagekey === "mobile-view");

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <div>
      {windowSize.isLargeScreenView ? (
        <div className="flex flex-row content-center">
          <div className="flex flex-col justify-center w-7/12 min-h-full lg:w-8/12 lg:py-4">{children}</div>
          <div className="flex flex-col w-4/12 h-56 ml-auto lg:w-4/12 ">{largeViewImageElement}</div>
        </div>
      ) : (
        <>
          <div className="flex flex-row content-center">
            <span className="flex flex-col justify-center w-7/12 align-top lg:w-8/12 lg:py-4 min-h-56">{children.length ? children[0] : children}</span>
            <span className="flex flex-col w-5/12 h-56 align-top lg:w-4/12">{smallViewImageElement}</span>
          </div>
          {!children.length
            ? null
            : children.map((child, index) => {
                if (index > 0) {
                  return (
                    <span key={uuidv4()} className="inline-block w-full align-top lg:w-8/12 lg:py-4">
                      {child}
                    </span>
                  );
                }

                return null;
              })}
        </>
      )}
    </div>
  );
};
