import React from "react";
import { v4 as uuidv4 } from "uuid";

export const getFormattedPriceFor = (row) => {
  const multipleValues = row.text.indexOf(" - ") > 0;
  const cellContents = [];
  const dollarTemplate = (
    <span key={uuidv4()} className="text-xl align-top">
      $
    </span>
  );
  const singleLinePriceTemplate = (
    <span key={uuidv4()} className="block w-full py-1 text-3xl font-bold">
      {cellContents}
    </span>
  );
  const multiLinePriceTemplate = (
    <span key={uuidv4()} className="block w-full py-1 text-2xl font-bold">
      {cellContents}
    </span>
  );
  const lineBreak = <br />;
  const prefixTemplate = row.disableDollarPrefix ? <span></span> : dollarTemplate;

  if (multipleValues) {
    const values = row.text.split(" - ");

    values.forEach((value, index) => {
      cellContents.push(prefixTemplate);
      cellContents.push(value);

      if (index < values.length - 1) {
        cellContents.push(" - ");
        if (!row.showValuesOnSingleLine) {
          cellContents.push(lineBreak);
        }
      }
    });

    return row.showValuesOnSingleLine ? singleLinePriceTemplate : multiLinePriceTemplate;
  }

  cellContents.push(prefixTemplate);
  cellContents.push(row.text);

  return singleLinePriceTemplate;
};
