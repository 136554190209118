import React from "react";

/**
 * Context object used to track the users ip based geo location data.
 * 
 * This context's value is used to store the result of the useIpGeoData hook.
 */
const IpGeoDataContext = React.createContext(null);

export default IpGeoDataContext;
