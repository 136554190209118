import productCategoryConfigurationData from ".././../data/productCategoryConfigurationData";
import { isValid } from "../../utils/ArrayUtils";
import { isNullEmptyOrUndefined, convertToLowerCase } from "../../utils/StringUtils";

const { productCategoryConfigurations } = productCategoryConfigurationData;

/**
 * Returns an Element that will be an SVG of the icon for the requested menu.
 * 
 * @param {String} menuName Contains the menu name for the product category. Used to look up the navbar icon from the product category data.
 * @returns {Element} Containing the request SVG icon.
 */
export const getMenuIconFor = menuName => {
  if(isNullEmptyOrUndefined(menuName)){
    throw new Error("Null, empty or undefined menuName. Unable to query product category icon.");
  }

  const productCategory = productCategoryConfigurations.find((config) => convertToLowerCase(config.category) === convertToLowerCase(menuName));
  return productCategory.navbarIcon ?? null;
};

/**
 * Extracts the menu data from the sites edge data.
 * 
 * @param {Array} edges The page edge data to query.
 * @returns {Array} An array containing the distinct, cleaned page data.
 */
export const getMenuDataFrom = edges => {
  if (isValid(edges)) {
    return edges.map((e) => e.node.fields);
  }

  throw new Error("Null or empty edges array, unable to extract menu data");
};

export const getDistinctParentMenuDataFrom = menus => {
  if (isValid(menus)) {
    return Array.from(new Set(menus.map((m) => m.parentMenu))).sort();
  }

  throw new Error("Null or empty menus array, unable to extract parent menu data");
};

export const getParentSubMenusFor = (parentMenuKey, menuData) => {
  if (parentMenuKey === undefined || parentMenuKey === null || parentMenuKey === "") {
    throw new Error("Null or Empty parentMenuKey, unable to extract sub menu data");
  }

  if (!isValid(menuData)) {
    throw new Error("Null or empty menuData array, unable to extract sub menu data");
  }

  return menuData
    .filter((m) => m.parentMenu.toLowerCase().indexOf(parentMenuKey.toLowerCase()) !== -1)
    .sort((a, b) => {
      if (a.menuText < b.menuText) return -1;
      if (a.menuText > b.menuText) return 1;
      return 0;
    });
};

export const createMenuKeyFrom = (menuName) => {
  if (menuName === undefined || menuName === null || menuName === "") {
    throw new Error("Null or Empty menuName, unable to create menu key");
  }

  return menuName.toLowerCase().replace(" ", "_");
};

const removeHyphensFrom = (text) => {
  if (text === undefined || text === null || text === "") {
    throw new Error("Null or Empty text, unable to remove hyphens from supplied text");
  }

  const hyphen = "-";
  const space = " ";

  return text.split(hyphen).join(space);
};

const convertToSentenceCase = (text) => {
  if (text === undefined || text === null || text === "") {
    throw new Error("Null or Empty text, unable convert to sentence case text");
  }

  const space = " ";

  const sentenceElements = text
    .toLowerCase()
    .split(space)
    .map((element) => element[0].toUpperCase() + element.slice(1));

  return sentenceElements.join(space);
};

export const getCasedSpacedMenuTextFrom = (text) => {
  if (text === undefined || text === null || text === "") {
    throw new Error("Null or Empty text, unable to generate menu text");
  }

  return convertToSentenceCase(removeHyphensFrom(text));
};

export const generateSubCategorySlugFor = (subMenuLink) => {
  if (!subMenuLink) {
    throw new Error("Null or Empty subMenuLink, unable to generate sub category slug");
  }

  const { parentMenu, menuText, slug } = subMenuLink;

  const productCategory = productCategoryConfigurations.find((config) => config.category.toLowerCase() === parentMenu.toLowerCase());
  const isPremiumSubCategory = productCategory.premiumSubCatagories.indexOf(menuText.toLowerCase()) >= 0;

  const premiumSlug = "/premium";

  return isPremiumSubCategory ? premiumSlug : slug;
};
