import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import RentersIllustration from "../../../../svg/ComparisonResultPageIllustrations/rentorsinsurance.svg";
import SetmeUpSVG from "../../../../svg/ComparisonResultGridLogos/rentersinsurance/setmeup.svg";
import ErenterPlanSVG from "../../../../svg/ComparisonResultGridLogos/rentersinsurance/erenterplan.svg";
import EffectiveCoverageSVG from "../../../../svg/ComparisonResultGridLogos/rentersinsurance/effectivecoverage.svg";
import AssurantSVG from "../../../../svg/ComparisonResultGridLogos/rentersinsurance/assurant.svg";
import PolicyGeniusSVG from "../../../../svg/ComparisonResultGridLogos/rentersinsurance/policygenius.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  RentersIllustration,
  SetmeUpSVG,
  ErenterPlanSVG,
  EffectiveCoverageSVG,
  AssurantSVG,
  PolicyGeniusSVG,
  React
};