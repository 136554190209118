import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import BankingIllustration from "../../../../svg/ComparisonResultPageIllustrations/personalbanking.svg";
import VioSVG from "../../../../svg/ComparisonResultGridLogos/personalbanking/viobank.svg";
import CitibankSVG from "../../../../svg/ComparisonResultGridLogos/personalbanking/citibank.svg";
import AllySVG from "../../../../svg/ComparisonResultGridLogos/personalbanking/ally.svg";
import MarcusSVG from "../../../../svg/ComparisonResultGridLogos/personalbanking/marcus.svg";
import PNCSVG from "../../../../svg/ComparisonResultGridLogos/personalbanking/pncbank.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  BankingIllustration,
  VioSVG,
  CitibankSVG,
  AllySVG,
  MarcusSVG,
  PNCSVG,
  React
};