import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import LoanInsuranceIllustration from "../../../../svg/ComparisonResultPageIllustrations/refinancingloan.svg";
import FigureSVG from "../../../../svg/ComparisonResultGridLogos/refinancingloans/figure.svg";
import LoanDepotSVG from "../../../../svg/ComparisonResultGridLogos/refinancingloans/loandepot.svg";
import AmeriSaveSVG from "../../../../svg/ComparisonResultGridLogos/refinancingloans/amerisave.svg";
import QuickenLoansSVG from "../../../../svg/ComparisonResultGridLogos/refinancingloans/quicken.svg";
import BetterSVG from "../../../../svg/ComparisonResultGridLogos/refinancingloans/better.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  LoanInsuranceIllustration,
  FigureSVG,
  LoanDepotSVG,
  AmeriSaveSVG,
  QuickenLoansSVG,
  BetterSVG,
  React
};