import React from "react";
import { Button } from "antd";

export default () => {
  return (
    <div className="flex flex-wrap items-center content-center justify-center overflow-hidden ">
      <Button
        href="/app/login"
        size="large"
        type="primary"
        className="w-full text-white bg-gray-500 border border-gray-500 lg:text-xs 0 sm:mt-0 sm:mb-0 lg:mb-2 lg:w-full text-large lg:text-gray-500 lg:bg-yellow-500 lg:border-yellow-500 lg:rounded lg:hover:border-gray-500 lg:hover:bg-gray-500 lg:hover:text-white"
      >
        Registrarse / Entrar
      </Button>
    </div>
    // </div>
  );
};
