import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import BusinessLoansIllustration from "../../../../svg/ComparisonResultPageIllustrations/businessloans.svg";
import CCUSVG from "../../../../svg/ComparisonResultGridLogos/businessloans/consumercreditunion.svg";
import CurrencySVG from "../../../../svg/ComparisonResultGridLogos/businessloans/currency.svg";
import BlueVineSVG from "../../../../svg/ComparisonResultGridLogos/businessloans/bluevine.svg";
import KabbageSVG from "../../../../svg/ComparisonResultGridLogos/businessloans/kabbage.svg";
import SmartBizSVG from "../../../../svg/ComparisonResultGridLogos/businessloans/smartbiz.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultHeroUserLocation,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  BusinessLoansIllustration,
  CCUSVG,
  CurrencySVG,
  BlueVineSVG,
  KabbageSVG,
  SmartBizSVG,
  React
};