import React, { useContext } from "react";
import { Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import WindowSizeContext from "../../contexts/windowSizeContext";

export default (props) => {
  const { cellText, useIcon, usePopover, popOverContents, popOverTitle, icon, className } = props;
  const windowSize = useContext(WindowSizeContext);

  if (usePopover) {
    const popOverContentLines = popOverContents.split("|");

    const createPopOverContents = (lines) => {
      if (lines.length < 2) {
        return <span>{lines[0]}</span>;
      }

      return (
        <ul className="px-4 list-disc">
          {lines.map((line) => {
            const itemKey = uuidv4();
            return <li key={itemKey}>{line}</li>;
          })}
        </ul>
      );
    };

    return (
      <p className={className}>
        <Popover content={createPopOverContents(popOverContentLines)} trigger={windowSize.isLargeScreenView ? "hover" : "click"} title={popOverTitle || "More Information"}>
          <InfoCircleOutlined className="cursor-pointer" />
        </Popover>
      </p>
    );
  }

  if (useIcon) {
    return <p className={className}>{icon.toLowerCase() === "tick" ? <CheckOutlined className="font-extrabold text-green-500" /> : <CloseOutlined className="font-bold text-red-500" />}</p>;
  }

  return <p className={`${className} text-sm`}>{cellText === "" ? " - " : cellText}</p>;
};
