import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import EmailIllustration from "../../../../svg/ComparisonResultPageIllustrations/emailmarketingservices.svg";
import ConstantContactSVG from "../../../../svg/ComparisonResultGridLogos/emailmarketingservices/constantcontact.svg";
import MoosendSVG from "../../../../svg/ComparisonResultGridLogos/emailmarketingservices/moosend.svg";
import MailChimpSVG from "../../../../svg/ComparisonResultGridLogos/emailmarketingservices/mailchimp.svg";
import ConvertKitSVG from "../../../../svg/ComparisonResultGridLogos/emailmarketingservices/convertkit.svg";
import HubSspotSVG from "../../../../svg/ComparisonResultGridLogos/emailmarketingservices/hubspot.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  EmailIllustration,
  ConstantContactSVG,
  MoosendSVG,
  MailChimpSVG,
  ConvertKitSVG,
  HubSspotSVG,
  React
};