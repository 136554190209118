export const isVisible = (renderMode) => {
  if (!renderMode) {
    throw new Error("Null renderMode, unable to get determine render mode");
  }

  return renderMode.toLowerCase() === "visible" || false;
};

export const getSortedDataRowsUsing = (columnConfiguration, renderMode) => {
  if (!columnConfiguration) {
    throw new Error("Null or Empty column configuration, unable to get data rows");
  }

  const dataRows = isVisible(renderMode) ? columnConfiguration.rows : columnConfiguration.expansionRows;

  return dataRows.sort((a, b) => {
    return a.indx - b.index;
  });
};

export const shouldApplyBackgroundTo = (row) => {
  if (!row) {
    throw new Error("Null or Empty row, unable to determine if it is even or not");
  }

  return parseInt(row.index) % 2 === 0;
};
