import React, { useContext } from "react";
import Navbar from "../Navbar";
import WindowSizeContext from "../../contexts/windowSizeContext";

/**
 * Creates a hero container element for the Comparison results page. This contains the Navbar and the text and image for the page.
 *
 * @returns (element) An element that renders the app's Navbar in a header container and then the child elements passed to this component.
 */
export default ({ children }) => {
  const windowSize = useContext(WindowSizeContext);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <div className="w-full bg-gradient-to-b from-white to-blue-100">
      <div className="container mx-auto">
        <div className={`flex flex-wrap ${windowSize.isLargeScreenView ? `pb-64 ` : `pb-8 `} overflow-hidden`}>
          <div className="w-full overflow-hidden">
            <header>
              <Navbar />
            </header>
          </div>
          <main>
            <div className="flex flex-wrap pt-4 overflow-hidden lg:pt-10">
              <div className="w-full overflow-hidden xl:w-1/12"></div>
              <div className="w-full overflow-hidden xl:w-10/12">{children}</div>
              <div className="w-full overflow-hidden xl:w-1/12"></div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};
