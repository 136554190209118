import React from "react";
import * as resultColumnUtils from "./comparisonResultColumnUtils";
import ComparisonResultColumnHeaderCell from "../ComparisonResultColumnHeaderCell";
import ComparisonResultColumnCell from "../ComparisonResultColumnCell";
import ComparisonResultColumnPriceCell from "../ComparisonResultColumnPriceCell";
import ComingSoonIcon from "../../../svg/comingsoon.svg";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { columnConfiguration, imageMap, renderMode, parentGridMetaData } = props;
  const columnHeaderConfiguration = resultColumnUtils.getColumnHeaderConfigurationFrom(columnConfiguration.rows);
  const columnRowConfiguration = resultColumnUtils.getColumnRowConfigurationUsing(columnConfiguration, renderMode);
  const shouldShowHeaderCell = resultColumnUtils.getHeaderCellVisibilityFrom(parentGridMetaData);
  const isLabelledColumn = columnConfiguration.isLabelledColumn;
  const borderColour = isLabelledColumn ? "border-yellow-500" : "border-gray-200";
  const supplierLogo = imageMap.find((i) => i.imageKey === columnHeaderConfiguration.heading.imageKey);

  const isFirstData = (row) => {
    return row.index ? row.index === 2 : false;
  };

  const isLast = (row) => {
    return row.index ? row.index === columnRowConfiguration[columnRowConfiguration.length - 1].index : false;
  };

  return (
    <div className={`w-full border-b ${borderColour} lg:w-1/5`}>
      <ComparisonResultColumnHeaderCell
        key={uuidv4()}
        parentGridMetaData={parentGridMetaData}
        columnConfiguration={columnConfiguration}
        columnHeaderConfiguration={columnHeaderConfiguration}
        className={`${resultColumnUtils.isVisible(renderMode) && shouldShowHeaderCell ? `block` : `hidden`} border-b ${borderColour} mt-px`}
        willBeVisible={resultColumnUtils.isVisible(renderMode)}
      >
        {columnConfiguration.isComingSoon ? <ComingSoonIcon key={uuidv4()} /> : supplierLogo ? supplierLogo.element : null}
      </ComparisonResultColumnHeaderCell>
      {columnRowConfiguration.map((row) => {
        let rowStyles = `text-gray-600 text-center flex items-center justify-center border-r ${borderColour} ${isLabelledColumn ? `border-l -ml-px` : ``}`;
        if (resultColumnUtils.shouldApplyBackgroundTo(row)) {
          rowStyles = `bg-blue-100 text-gray-500 text-center px-2 flex items-center justify-center border-r ${borderColour} ${isLabelledColumn ? `border-l -ml-px` : ``}`;
        }
        if (row.isPriceCell) {
          return (
            <ComparisonResultColumnPriceCell
              key={uuidv4()}
              parentColumnConfiguration={columnConfiguration}
              row={row}
              className={`py-0 ${isFirstData(row) ? `h-32 block ${borderColour}` : `h-12`} ${shouldShowHeaderCell ? `` : `border-t ${borderColour}`} ${rowStyles}`}
            />
          );
        } else {
          return (
            <ComparisonResultColumnCell
              key={uuidv4()}
              cellText={row.text}
              useIcon={row.useIcon}
              icon={row.icon}
              usePopover={row.usePopover}
              popOverContents={row.popOverContents}
              popOverTitle={row.popOverTitle}
              className={`${isFirstData(row) ? `h-32 block ${borderColour}` : `h-12`} ${isLast(row) ? `` : ``} ${rowStyles}`}
            />
          );
        }
      })}
    </div>
  );
};
