import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import CellPlanIllustration from "../../../../svg/comparison-illustrations/cellphones.svg";
import AttSvg from "../../../../svg/ComparisonResultGridLogos/cellplans/att.svg";
import SprintSvg from "../../../../svg/ComparisonResultGridLogos/cellplans/sprint.svg";
import TmobileSvg from "../../../../svg/ComparisonResultGridLogos/cellplans/tmobile.svg";
import VerizonSvg from "../../../../svg/ComparisonResultGridLogos/cellplans/verizon.svg";
import XfinitySvg from "../../../../svg/ComparisonResultGridLogos/cellplans/xfinitymobile.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  CellPlanIllustration,
  AttSvg,
  SprintSvg,
  TmobileSvg,
  VerizonSvg,
  XfinitySvg,
  React
};