import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import CreditMonitoringIllustration from "../../../../svg/ComparisonResultPageIllustrations/creditmonitoringservices.svg";
import LifeLockSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditmonitoring/lifelock.svg";
import CreditSesameSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditmonitoring/creditsesame.svg";
import PrivacyGuardSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditmonitoring/privacyguard.svg";
import IdentityForceSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditmonitoring/identityforce.svg";
import ExperianSVG from "../../../../svg/ComparisonResultGridLogos/personalcreditmonitoring/experian.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  CreditMonitoringIllustration,
  LifeLockSVG,
  CreditSesameSVG,
  PrivacyGuardSVG,
  IdentityForceSVG,
  ExperianSVG,
  React
};