import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

/**
 * Renders a Product Category recommendation tile based on the provided configuration data.
 *
 * @prop (configuration) A required object containing the configuration data. Should provide image, title, text and slug fields.
 * @returns (element) A div element styled and configured to represent a product category recommendation tile.
 */
const ComparisonPageRecommendation = (props) => {
  const { configuration } = props;

  return (
    <div key={uuidv4()} className="inline-block px-1 py-2 first:pl-0 last:pr-0 md:w-1/3">
      <div className="flex flex-col h-full px-4 py-4 bg-blue-100 border border-gray-100 rounded-xs">
        <div className="flex items-center mb-2">
          <div className="inline-flex items-center justify-center flex-shrink-0 mx-auto text-white rounded-full">{configuration.image}</div>
        </div>
        <div className="h-32 align-middle">
          <p className="h-12 text-sm text-center text-gray-900 ">{configuration.title}</p>
          <p className="h-20 text-sm text-center text-blue-500">{configuration.text}</p>
        </div>
        <div className="mt-2">
          <div className="text-center">
            <Link to={configuration.slug} className="p-2 text-gray-500 align-middle bg-yellow-500 border border-yellow-500 rounded hover:border-gray-500 hover:bg-gray-500 hover:text-yellow-500">
              Compara
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ComparisonPageRecommendation.propTypes = {
  configuration: PropTypes.object.isRequired,
};

export default ComparisonPageRecommendation;
