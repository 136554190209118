import ComparisonResultsPageHero from "../../../../src/components/ComparisonResultsPageHero";
import ComparisonResultPageTextWithImageSection from "../../../../src/components/ComparisonResultPageTextWithImageSection";
import ComparisonResultGrid from "../../../../src/components/ComparisonResultGrid";
import ComparisonResultColumnContent from "../../../../src/components/ComparisonResultColumnContent";
import ComparisonResultColumnContentBackgroundContainer from "../../../../src/components/ComparisonResultColumnContentBackgroundContainer";
import ComparisonResultGettingStartedBlock from "../../../../src/components/ComarisonResultPageGetStartedBlock";
import ComparisonPageRecommendations from "../../../../src/components/ComparisonPageRecommendations";
import ComparisonResultPageLearnMoreContainer from "../../../../src/components/ComparisonResultPageLearnMoreContainer";
import ComparisonResultHeroUserLocation from "../../../../src/components/ComparisonResultHeroUserLocation";
import BusinessCreditCardsIllustration from "../../../../svg/ComparisonResultPageIllustrations/businesscreditcards.svg";
import HomeInsuranceSvg from "../../../../svg/ProductCategoryIcons/Insurance/homeinsurance.svg";
import CarInsuraceSvg from "../../../../svg/ProductCategoryIcons/Insurance/carinsurance.svg";
import ElectricityRatesSvg from "../../../../svg/ProductCategoryIcons/Utilities/electricityrates.svg";
import LoansSvg from "../../../../svg/ProductCategoryIcons/Loans/personalloans.svg";
import CreditCardsSvg from "../../../../svg/ProductCategoryIcons/Financial/personalcreditcards.svg";
import PasswordManagersSvg from "../../../../svg/ProductCategoryIcons/Home/passwordmanager.svg";
import CCUSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditcards/consumercreditunion.svg";
import AmericanExpressSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditcards/americanexpress.svg";
import USBankSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditcards/usbank.svg";
import CitiBankSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditcards/citibank.svg";
import BrexSVG from "../../../../svg/ComparisonResultGridLogos/businesscreditcards/brex.svg";
import * as React from 'react';
export default {
  ComparisonResultsPageHero,
  ComparisonResultPageTextWithImageSection,
  ComparisonResultGrid,
  ComparisonResultColumnContent,
  ComparisonResultColumnContentBackgroundContainer,
  ComparisonResultGettingStartedBlock,
  ComparisonPageRecommendations,
  ComparisonResultPageLearnMoreContainer,
  ComparisonResultHeroUserLocation,
  BusinessCreditCardsIllustration,
  HomeInsuranceSvg,
  CarInsuraceSvg,
  ElectricityRatesSvg,
  LoansSvg,
  CreditCardsSvg,
  PasswordManagersSvg,
  CCUSVG,
  AmericanExpressSVG,
  USBankSVG,
  CitiBankSVG,
  BrexSVG,
  React
};