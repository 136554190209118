import React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";

const buildSuccessNotificationArgsFrom = (title, body) => {
  return {
    message: title,
    description: body,
    duration: 4,
    icon: <CheckOutlined style={{ color: "#102131" }} />,
    style: {
      backgroundColor: "#ffbd18",
      color: "#102131",
    },
  };
};

const buildFailedNotificationArgsFrom = (title, body) => {
  return {
    message: title,
    description: body,
    duration: 4,
    icon: <CloseOutlined style={{ color: "#0c0100" }} />,
    style: {
      backgroundColor: "#ffbd18",
      color: "#849dae",
    },
  };
};

export default {
  buildSuccessNotificationArgsFrom,
  buildFailedNotificationArgsFrom,
};
