import { useStaticQuery, graphql } from "gatsby";
import * as navbarUtils from "../components/Navbar/navbarUtils";

/**
 * Core Query Hook used to query and return navigation and menu data based on the generated MDX content and pages.
 *
 * @returns{object} An object which provides access to the processed menu navigation objects and the grouped parent menu data.
 */
function useNavigationData() {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { fields: { parentMenu: { nin: ["blog", "smartprices"] } } }) {
          edges {
            node {
              id
              fields {
                parentMenu
                menuText
                slug
              }
            }
          }
        }
      }
    `
  );

  const edges = allMdx.edges;
  const menuData = navbarUtils.getMenuDataFrom(edges);
  const parentMenuData = navbarUtils.getDistinctParentMenuDataFrom(menuData);

  return {
    menuData: menuData,
    parentMenuData: parentMenuData,
  };
}

export default useNavigationData;
