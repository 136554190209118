import React, { useContext } from "react";
import ComparisonResultGridMobileView from "../ComparisonResultGridMobileView";
import ComparisonResultGridLargeView from "../ComparisonResultGridLargeView";
import * as gridUtils from "./comparisonResultGridUtils";
import WindowSizeContext from "../../contexts/windowSizeContext";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { columnConfiguration } = props;
  const labelColumnConfiguration = gridUtils.getGridLabelColumnFrom(columnConfiguration.columns);
  const dataColumnsConfiguration = gridUtils.getGridDataColumnsFrom(columnConfiguration.columns);
  const gridImageMap = gridUtils.createResultGridImageMapFrom(props.children);
  const gridMetaData = gridUtils.generateGridMetaDataUsing(labelColumnConfiguration, dataColumnsConfiguration);

  const windowSize = useContext(WindowSizeContext);
  gridMetaData.showHeaderRow = windowSize.isLargeScreenView;

  const renderExpander = gridUtils.renderExpanderUsing(labelColumnConfiguration);

  if (typeof window === `undefined`) {
    return <></>;
  }

  return (
    <>
      {windowSize.isLargeScreenView ? (
        <ComparisonResultGridLargeView
          key={uuidv4()}
          columnConfiguration={columnConfiguration}
          labelColumnConfiguration={labelColumnConfiguration}
          dataColumnsConfiguration={dataColumnsConfiguration}
          gridImageMap={gridImageMap}
          gridMetaData={gridMetaData}
          renderExpander={renderExpander}
        />
      ) : (
        <ComparisonResultGridMobileView
          key={uuidv4()}
          columnConfiguration={columnConfiguration}
          labelColumnConfiguration={labelColumnConfiguration}
          dataColumnsConfiguration={dataColumnsConfiguration}
          gridImageMap={gridImageMap}
          gridMetaData={gridMetaData}
          renderExpander={renderExpander}
        />
      )}
    </>
  );
};
