import React, { useState } from "react";
import ComparisonResultGridRowLabelColumn from "../ComparisonResultGridRowLabelColumn";
import ComparisonResultColumn from "../ComparisonResultColumn";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

export default (props) => {
  const { dataColumnsConfiguration, labelColumnConfiguration, gridImageMap, renderExpander } = props;
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <div className={`${renderExpander ? `block` : `hidden`} w-full text-white bg-blue-500 -mt-1`}>
      <button
        className="w-full text-sm text-center lg:w-1/4 focus:border-0 focus:outline-none"
        onClick={() => {
          toggleExpansion(!isExpanded);
        }}
      >
        {labelColumnConfiguration.expanderText || "Click to view extra details here"}
        <UpOutlined className={`pl-2 align-middle  ${isExpanded ? `inline-block` : `hidden`}`} />
        <DownOutlined className={`align-middle pl-2 ${isExpanded ? `hidden` : `inline-block`}`} />
      </button>
      <div className={`${isExpanded ? `inline-flex` : `hidden`} bg-white w-full`}>
        <ComparisonResultGridRowLabelColumn key={uuidv4()} columnConfiguration={labelColumnConfiguration} renderMode="expandable" />
        <div className="flex flex-wrap w-1/2 lg:w-3/4 ">
          {dataColumnsConfiguration.map((column) => (
            <ComparisonResultColumn key={uuidv4()} columnConfiguration={column} imageMap={gridImageMap} renderMode="expandable" />
          ))}
        </div>
      </div>
    </div>
  );
};
